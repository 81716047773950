<template>
  <x-err-dialog :visible="visible"
                class="red-packet-dialog__wrap">
    <div class="red-packet-content">
      <img src="./imgs/close.png"
           alt=""
           class="close"
           @click.stop="closeDialog">
      <div class="red-number">
        <p class="title">评论红包</p>
        <p class="number">{{info.coin_total}}书币</p>
      </div>
      <div class="coin-rest-wrap">
        <div class="coin-rest">
          <span>原创奖金币红包</span>
          <span>还剩<i>{{user.rank_coin}}</i>奖金币</span>
        </div>
        <div class="coin-rest">
          <span>账户余额</span>
          <span><i>{{user.balance}}</i>原创币</span>
        </div>
      </div>
      <div class="tips-wrap">
        <img src="./imgs/tips.png"
             alt="">
        <span>优先扣除原创奖金币红包</span>
      </div>
      <p class="confirm-send"
         @click.stop="sure">确认支付</p>
    </div>
  </x-err-dialog>
</template>

<script>
/* eslint-disable */
import errDialog from "../err-dialog";

import { sendRedPacketByAuthor } from "@/api";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  methods: {
    closeDialog() {
      this.reject();
      this.close();
    },
    sure() {
      this.$toast({
        type: "loading",
        message: "请稍等...",
        forbidClick: true,
        duration: 0
      });
      sendRedPacketByAuthor(this.info)
        .then(r => {
          setTimeout(() => {
            this.close();
          }, 500);
        })
        .catch(e => {
          this.$notify(e.msg);
          this.$toast.clear();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.red-packet-dialog__wrap {
  .red-packet-content {
    position: relative;
    width: 90%;
    margin: 0 auto;

    .red-number {
      padding-top: 0.94rem;
      padding-bottom: 0.59rem;
      font-size: 0.26rem;
      color: #333;
      border-bottom: 1px solid #f7f7f7;

      .number {
        font-weight: bold;
        font-size: 0.56rem;
      }
    }

    .coin-rest-wrap {
      padding: 0.29rem 0;
      border-bottom: 1px solid #f7f7f7;

      .coin-rest {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.24rem;
        color: #666;

        i {
          color: #ef5952;
        }

        &:first-child {
          margin-bottom: 0.38rem;
        }
      }
    }

    .tips-wrap {
      font-size: 0;
      color: #ef5952;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0.3rem;
      padding-bottom: 0.6rem;

      img {
        width: 0.3rem;
        height: 0.3rem;
        margin-right: 0.15rem;
      }

      span {
        font-size: 0.24rem;
      }
    }

    .confirm-send {
      width: 3rem;
      height: 0.7rem;
      line-height: 0.7rem;
      text-align: center;
      margin: 0 auto 0.4rem;
      background: #ef5952;
      border-radius: 0.1rem;
      color: #fff;
      font-size: 0.28rem;
    }

    .close {
      position: absolute;
      width: 0.3rem;
      height: 0.3rem;
      top: 0.2rem;
      right: 0;
    }
  }
}
</style>