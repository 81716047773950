/*
 * @Author: ZhengXiaowei
 * @Date: 2019-01-09 14:06:31
 * @LastEditors  : ZhengXiaowei
 * @LastEditTime : 2019-12-31 15:00:56
 * @Description: file content
 */
/* eslint-disable */
/**
 * cookie工具
 */
export const cookie = {
  getCookie(name) {
    var arr = document.cookie.replace(/\s/g, "").split(";");
    for (var i = 0; i < arr.length; i++) {
      var tempArr = arr[i].split("=");
      if (tempArr[0] == name) {
        return decodeURIComponent(tempArr[1]);
      }
    }
    return "";
  },

  setCookie(name, value, days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    document.cookie =
      name + "=" + value + ";domain=.ycsd.cn;path=/;expires=" + date;
  },

  removeCookie(name) {
    // 设置已过期，系统会立刻删除cookie
    this.setCookie(name, "", -1);
  },

  clearAllCookie() {
    // 清除所有的cookie
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
      for (var i = keys.length; i--; ) this.removeCookie(keys[i]);
    }
  }
};

/**
 * 字符串转换工具
 */

export const convert = {
  toBoolean(string) {
    if (!string) return false;
    return string === "true" ? true : false;
  },

  toNumber(string) {
    if (typeof string === "string") {
      if (string) return 0;
      return parseInt(string);
    } else if (typeof string === "boolean") {
      if (string) return 1;
      else return 0;
    }
  }
};

/**
 * 判断对象是否为空
 * @param {object} obj 对象
 */
export const isEmptyObject = obj => {
  for (let i in obj) return false;
  return true;
};

/**
 * 判断是否为数组
 * @param {array} arr 数组
 */
export const isArray = arr => {
  if (Array.isArray(arr)) return true;
  return false;
};

/**
 * 判断是否在微信浏览器下
 */
export const is_WeChat = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") return 1;
  else return 0;
};

/**
 * 防抖
 * @export
 * @param {any} func 需要防抖的函数
 * @param {any} delay 延迟
 * @returns
 */
export const debounce = (func, delay) => {
  let timer;

  return function(...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};
/**
 * @description url格式化
 * @author ZhengXiaowei
 * @date 2019-05-24
 * @export
 * @param {*} urlObj
 * @returns
 */
export function urlFormat(urlObj) {
  if (!urlObj) return null;
  let url = "";
  if (urlObj.host) url += urlObj.host;
  if (urlObj.pathname) url += urlObj.pathname;
  if (urlObj.query) {
    url += "?";
    for (let i in urlObj.query) {
      url += i;
      if (urlObj.query[i]) url += "=" + urlObj.query[i];
      url += "&";
    }
    return (url = url.slice(0, -1));
  }
  return encodeURI(url);
}

/**
 * 格式化时间成 x年x月的形式
 * @param {*} date 日期 20xx-xx-xx
 * @returns 格式化后的日期
 */
export const formatDate = date => {
  let subDate = date.substr(0, 10).substr(5);
  // 去掉0
  subDate = subDate.replace(/0/g, "").split("-");
  let month = subDate[0] + "月";
  let day = subDate[1] + "日";
  return month + day;
};

export const recharge = ({
  pay_url,
  product_id,
  bid,
  cid,
  is_activity = false,
  activity_id,
  send_pos
}) => {
  const {
    uid,
    distribution_channel_id,
    send_order_id,
    from,
    crm,
    pay_config: { pay_config }
  } = window.ycsd;
  const a = document.createElement("a");
  const default_pay_url = pay_config[0].redirect_url;
  const redirect =
    bid && cid
      ? {
          host: location.origin,
          pathname: "/reader/" + bid + "/" + cid
        }
      : {
          host: location.origin,
          pathname: "/stock/recent"
        };
  console.log(redirect);
  const href = {
    host: pay_url ? pay_url : default_pay_url,
    query: {
      product_id: product_id,
      uid,
      distribution_channel_id,
      send_order_id,
      from,
      bid,
      cid,
      crm,
      send_pos,
      pay_redirect_url: encodeURIComponent(urlFormat(redirect))
    }
  };
  if (is_activity) href.query.activity_id = activity_id;
  a.href = urlFormat(href);
  a.click();
};

const store = localStorage;
const day_key = "day";
const today = new Date().toLocaleDateString();
const max_open_limit = 3;
let activity_init = +store.getItem("is_init") || 1;
// 清除缓存 方便下次活动使用
// let activity_init = 0;

let openDays = +store.getItem("open_days") || 0;
// 每天只执行一次
export const runOnceEveryDay = (recordTimes = true) => {
  // 活动重置
  const isOpen = store.getItem(day_key);
  // 日期更新 重新执行
  if (isOpen) {
    if (isOpen !== today) {
      cacheDayTime(recordTimes);
      return isActive();
      // return Promise.resolve();
    }
  } else {
    cacheDayTime(recordTimes);
    return isActive();
    // return Promise.resolve();
  }
  // return Promise.reject("opened");
  return Promise.reject();
};

function cacheDayTime(recordTime) {
  // let days;
  store.setItem(day_key, today);
  // if (++openDays <= 7) days = openDays;
  // else days = 1;
  recordTime && store.setItem("open_days", ++openDays);
}

export function isActive() {
  // 是否开通 不开通 弹3天 开通 每天弹
  const is_active = !!window.ycsd.week_card.is_activate;
  console.log(openDays);
  console.log(max_open_limit);
  console.log(openDays <= max_open_limit);
  if (is_active) return Promise.resolve();
  else if (openDays <= max_open_limit) return Promise.resolve();
  else return Promise.reject();
}

export function resetActivityCache() {
  const { img } = window.ycsd.activity.global;
  if (!img) {
    let keys = ["once", "open_days"];
    keys.forEach(key => store.removeItem(key));
    store.setItem("is_init", 2);
  }
}

export function unLoginUserReset() {
  store.removeItem("day");
  if (!openDays) store.removeItem("open_days");
  else store.setItem("open_days", --openDays);
}

// 只弹一次
const flag_key = "once";
export const runOnce = () => {
  const isRunned = store.getItem(flag_key);
  if (!isRunned) {
    store.setItem(flag_key, flag_key);
    return Promise.resolve();
  } else return Promise.reject();
};

// 只在指定时间弹出
const appointDate = "2019/5/12";
const isOpenToday = +store.getItem("is_open_today") || 0;
export const runInAppointDate = () => {
  if (!isOpenToday) {
    if (today === appointDate) {
      store.setItem("is_open_today", 1);
      return Promise.resolve();
    }
  }
  return Promise.reject();
};

const qa_day_key = "qa_day";
const qa_count_down_key = "start_time";
export const QAEveryDay = () => {
  const isOpen = store.getItem(qa_day_key);
  const start = +store.getItem(qa_count_down_key);
  // 日期更新 重新执行
  if (isOpen) {
    if (isOpen !== today) {
      // 每天第一次打开答题页面就记录开始答题的时间
      !start && store.setItem(qa_count_down_key, Date.parse(new Date()) / 1000);
      return Promise.resolve();
    }
  } else {
    !start && store.setItem(qa_count_down_key, Date.parse(new Date()) / 1000);
    return Promise.resolve();
  }
  return Promise.reject();
};

// 计算时间流动
export const QAGetTimeFlow = () => {
  let time = Date.parse(new Date()) / 1000;
  return time - +store.getItem(qa_count_down_key);
};

// 问答提交后的标记
export const QAMark = () => {
  return new Promise(resolve => {
    store.setItem(qa_day_key, today);
    store.removeItem(qa_count_down_key);
    resolve();
  });
};

export const formatTimeValue = time => {
  let s = +time;
  let m = 0;
  let h = 0;
  if (s > 60) {
    m = parseInt(s / 60);
    s = parseInt(s % 60);
    if (m > 60) {
      h = parseInt(m / 60);
      m = parseInt(m % 60);
    }
  }
  let result = "" + parseInt(s) + "秒";

  if (m > 0) {
    result = "" + parseInt(m) + "分" + result;
  }
  if (h > 0) {
    result = "" + parseInt(h) + "小时" + result;
  }
  return result;
};
