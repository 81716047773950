import Vue from "vue";
import SBook from "./book.vue";
import SBookMulti from "./book-multi.vue";
import SBookNoCover from "./book-no-cover.vue";
import SCommentItem from "./comment.vue";

Vue.component("SBook", SBook);
Vue.component("SBookMulti", SBookMulti);
Vue.component("SBookNoCover", SBookNoCover);
Vue.component("SCommentItem", SCommentItem);
