/*
 * @Author: ZhengXiaowei
 * @Date: 2019-02-21 11:54:38
 * LastEditors: 晓晓晓晓晓丶vv
 * LastEditTime: 2020-05-25 15:56:22
 * @Description: 路由入口文件
 */
/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
import store from "../store";
// import { cookie } from "@/utils";
import * as namespace from "../views";
import { afterRouteChange } from "@/utils/app_register";

// import { routerCustom } from "./options";

Vue.use(Router);

let behavior = "user";

addEventListener("popstate", function () {
  //我的事件要在vue-router事件之前绑定
  behavior = "browser";
});

const router = new Router({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    namespace.Home,
    namespace.Library,
    namespace.PersonalCenter,
    namespace.Detail,
    namespace.RechargeRecord,
    namespace.Account,
    namespace.RewardRecord,
    namespace.Comment,
    namespace.CommentDetail,
    namespace.BookCatalog,
    namespace.BookReader,
    namespace.BookRank,
    namespace.Pay,
    namespace.SetPhone,
    namespace.SetPwd,
    namespace.PayForOld,
    namespace.PayForSpecialTemplate,
    namespace.GiftRank,
    namespace.FansRank,
    namespace.SubscriptionRecord,
    namespace.Reward,
    namespace.ExpiredRecord,
    namespace.MyComment,
    namespace.MyCommentDetail,
    namespace.AuthorCenter,
    namespace.ReaderCenter,
    namespace.Stock,
    namespace.FanLevel,
    namespace.Notification,
    namespace.MyTicket,
    namespace.AuthorSay,
    namespace.SearchCenter,
    namespace.mobileManger,
    namespace.TeenAgers,
    namespace.TeenAgersLock,
    namespace.HisComment,
    namespace.HisInteraction,
    namespace.AccountInfo,
    namespace.CommentatorLevel,
    namespace.BookMonthly,
    namespace.Helper,
    namespace.Agreements,
    namespace.lastPage,
    // namespace.detailV2,
    namespace.BookReaderV2,
    namespace.recentV2,
    namespace.UserV2,
    namespace.Login,
    namespace.UnderTheShelf,
    // namespace.Sign,
    // namespace.Sign2,
    namespace.Sign3,
    namespace.Answer,
    namespace.Report,
    namespace.AuthorOperator,
    namespace.BookManager,
    namespace.DraftBox,
    namespace.ChapterManager,
    namespace.Calendar,
    namespace.ReaderBillboard,
    namespace.ExperienceRecord,
    namespace.SendRedPackets,
    namespace.UserRedPacket,
    namespace.AuthorRedPacket,
    namespace.AuthorRedPacketRecord,
    namespace.WeekInteractRank,
    namespace.RedDetail,
    namespace.PayForNormalTemplate,
    namespace.NotFound,
  ],
  linkActiveClass: "is-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// * 在beforeEach中判断是否需要登录 预先在cookie中存入to的fullPath
// * 其次在接口请求中根据状态码的登录情况判断是否跳转到登录页
// * 登录成功后 登录页的query中返回redirect参数 值为to.fullPath
router.beforeEach((toRouter, fromRouter, next) => {
  // * 针对首页的路由 需要做统计
  // routerCustom(toRouter, fromRouter);
  // * 默认每个页面跳转都返回到顶部
  // * 有部分页面的滚动容器不是 x-page__content
  // filterRouter(toRouter)
  // * 判断是否需要缓存页面
  console.log(toRouter);
  console.log("before router change");
  if (toRouter.meta.auth) {
    // * 将路由存入store中
    store.commit("system/SET_ROUTER_PATH", toRouter.fullPath);
  }
  // * 浏览器行为
  window.prevPage = fromRouter.fullPath;
  window.nextPage = toRouter.fullPath;
  toRouter.meta
    ? (toRouter.meta.behavior = behavior)
    : (toRouter.meta = { behavior });
  behavior = "user";
  console.log("begin router change");
  let is_app = !!window.ycsd.is_app;
  if (is_app) {
    // 记录是否是app环境
    store.commit("system/RECORD_APP_STATUS");
    // 修改title
    document.title = toRouter.meta.Chinese;
  }

  // * 静默登录
  // const isNeedLogin = toRouter.query.isLogin;
  // const serverLoginStatus = window.ycsd.islogin;
  // // * 未登录的用户 静默登录
  // if (isNeedLogin && !serverLoginStatus) {
  //   cookie.setCookie(
  //     "N_loginUrl",
  //     `${window.location.origin}${toRouter.fullPath}`
  //   );
  //   location.href = window.ycsd.login_redirect_silent;
  // }
  // return;
  next();
});

router.afterEach(function (router, from) {
  let is_app = !!window.ycsd.is_app;
  if (is_app) {
    // 路由跳转记录到栈
    store.dispatch("system/recordRouterStack", [router.path, router.fullPath]);
    afterRouteChange(router.meta.Chinese);
  }
  if (process.env.NODE_ENV !== "development") {
    // window.MtaH5.pgv();
    // window._hmt.push(["_trackPageview", router.fullPath + "#" + from.name]);
  }
});

export default router;
