/*
 * @Author: ZhengXiaowei
 * @Date: 2019-11-27 16:22:53
 * @LastEditors: ZhengXiaowei
 * @LastEditTime: 2019-11-27 16:23:40
 * @Description: vip标识
 */
/**
 * 主站vip显示标识
 */
export const MAIN_VIP_TARGET_MAP = {
  0: {
    title: "普通VIP",
    cover:
      "http://cdn-newyc.ycsd.cn/newycsd/wap/static2019-11-27/img/main_vip_0.png"
  },
  1: {
    title: "初级VIP",
    cover:
      "http://cdn-newyc.ycsd.cn/newycsd/wap/static2019-11-27/img/main_vip_1.png"
  },
  2: {
    title: "高级VIP",
    cover:
      "http://cdn-newyc.ycsd.cn/newycsd/wap/static2019-11-27/img/main_vip_2.png"
  }
};

/**
 * 分销站vip显示标识
 */
export const VIP_TARGET_MAP = {
  0: {
    title: "普通VIP",
    cover: "http://cdn-newyc.ycsd.cn/newycsd/wap/static2019-11-27/img/vip_0.png"
  },
  1: {
    title: "初级VIP",
    cover: "http://cdn-newyc.ycsd.cn/newycsd/wap/static2019-11-27/img/vip_1.png"
  },
  2: {
    title: "高级VIP",
    cover: "http://cdn-newyc.ycsd.cn/newycsd/wap/static2019-11-27/img/vip_2.png"
  }
};
