const action = {
  common: {
    getList: "list",
    forBook: "bookinfo",
    book: "bookClass"
  },
  library: {
    library: "books"
  },
  userMoney: {
    balance: "GetUserMoney",
    detail: "BonusDetail"
  },
  comment: {
    getList: "booklist",
    reply: "reply"
  },
  author: {
    base: "LevelInfo",
    whole: "HomeForEveryOne"
  },
  reader: {
    chapter: "buyChapter",
    book: "buySingleBook"
  }
};

export default action;
