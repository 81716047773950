<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-01-09 14:06:31
 * @LastEditors: 晓晓晓晓晓丶vv
 * @LastEditTime: 2020-05-08 16:45:11
 * @Description: file content
 -->
<template>
  <div class="bottom-entry">
    <div class="jumplist">
      <router-link tag="span"
                   :to="{name:Home}">首页</router-link>
      <router-link tag="span"
                   :to="{name:Library}">书库</router-link>
      <router-link tag="span"
                   :to="{name:BookRank}">排行榜</router-link>
      <span @click="toPay">充值</span>
      <!-- <router-link tag="span"
                   :to="{name:Pay}">充值</router-link> -->
      <router-link tag="span"
                   :to="{name:Report}">投诉</router-link>
      <router-link tag="span"
                   :to="{name:Help}"
                   :class="{'is-last': isApp}">帮助</router-link>
      <template v-if="!isApp">
        <a href="/logout"
           style="border:none">退出</a>
      </template>
    </div>
    <div class="contactus">
      <div class="phone">
        <p>客服工作时间 {{time}}</p>
        <p>电话: {{phone}}</p>
      </div>
      <!-- <a href="tencent://message/?uin=646949219&Menu=yes"> -->
      <!-- <img src="../../assets/QQQQQ.png"
           class="QQ"> -->
      <!-- </a> -->
    </div>
    <img src="../../assets/hy.png"
         class="hy">
    <p class="agreement"
       v-if="isApp">
      <a href="//m.app.ycsd.cn/serviceAgreement">《原创书殿用户协议》</a>和<a href="//m.app.ycsd.cn/shenming">《隐私政策》</a>
    </p>
    <div class="bottom-toast">Copyright © 2014-2020 原创书殿 ycsd.cn All rights reserved.</div>
  </div>
</template>

<script>
import {
  Pay,
  Home,
  Library,
  BookRank,
  Helper,
  Report
} from "../../views/index.js";
import { cookie } from "@/utils/index.js";
import { mapState } from "vuex";
import { switchRecharge } from "@/utils/app_register";

export default {
  name: "XFooter",
  data() {
    return {
      Home: Home.name,
      Library: Library.name,
      BookRank: BookRank.name,
      Pay: Pay.name,
      Help: Helper.name,
      Report: Report.name,
      time: window.ycsd.kf_time,
      phone: window.ycsd.kf_phone
    };
  },
  computed: {
    ...mapState("system", ["isApp"])
  },
  methods: {
    toPay() {
      if (this.isApp) {
        switchRecharge();
      } else {
        this.$router.push({ name: this.Pay });
      }
    }
  },
  mounted() {
    // let doc = document;
    // let s = doc.createElement("script");
    // s.setAttribute("id", "contact-us");
    // s.setAttribute(
    //   "src",
    //   "https://wp.qiye.qq.com/qidian/2852129501/8b0629a780ca41a1bbb66b7d9fc25b0e"
    // );
    // s.setAttribute("charset", "utf-8");
    // doc.querySelector(".bottom-entry").appendChild(s);
  },
  destroyed() {
    // let doc = document;
    // let iframes = doc.querySelectorAll("iframe");
    // for (let i = 0; i < iframes.length; i++) {
    //   doc.body.removeChild(iframes[i]);
    // }
  }
};
</script>

<style lang="scss" scoped>
.bottom-entry {
  background-color: #f7f7f7;
  padding-bottom: 0.4rem;
}
.jumplist {
  padding: 0 0.4rem;
  padding-top: 0.4rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.26rem;
  span,
  a {
    color: currentColor;
    width: 20%;
    text-align: center;
    border-right: solid #ededed 0.01rem;

    &.is-last {
      border-right-width: 0;
    }
  }
}
.contactus {
  color: #333;
  line-height: 0.5rem;
  margin-top: 0.3rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.2rem;
  font-size: 0.26rem;
  text-align: left;
  border-bottom: #f1f1f1 solid 0.01rem;
  padding-bottom: 0.1rem;
  .phone {
  }
  .QQ {
    align-self: center;
    width: 2rem;
    height: 0.7rem;
  }
}
.hy {
  margin-top: 0.4rem;
  width: 1.2rem;
  height: 0.5rem;
}
.bottom-toast {
  color: #999;
  font-size: 0.2rem;
  margin-top: 0.28rem;
}

.agreement {
  margin-top: 0.28rem;

  a {
    color: #ef5952;
    text-decoration: none;
  }
}
</style>

<style>
body {
  position: relative;
}

#qidian_wpa_2852129501_21 {
  position: absolute;
  right: 0.2rem;
  bottom: 2.4rem;
}

@media (max-width: 320px) {
  #qidian_wpa_2852129501_21 {
    bottom: 2.8rem;
  }
}
</style>

