/*
 * @Author: ZhengXiaowei
 * @Date: 2019-01-16 14:36:55
 * LastEditors: 晓晓晓晓晓丶vv
 * LastEditTime: 2020-08-06 15:10:56
 * @Description: 弹窗组件入口
 */
import Vue from "vue";

import ChapterPay from "./slots/chapter-pay.vue";
import ChapterBuy from "./slots/chapter-buy.vue";
import BookPay from "./slots/book-pay.vue";
import BookBuy from "./slots/book-buy.vue";
import NoLogin from "./slots/no-login.vue";
import SubscribeDialog from "./slots/subscribe.vue";
import SubscribeChapterDialog from "./slots/subscribe-chapter.vue";
import CustomDialog from "./slots/custom.vue";
import WeekSignDialog from "./slots/week-sign.vue";
import ActivityDialog from "./slots/activity.vue";
import FiveOneActivityDialog from "./slots/51-activity.vue";
import EasyActivityDailog from "./slots/easy-activity.vue";
import CrmActivityDialog from "./slots/crm-activity.vue";
import AnswerResultDialog from "./slots/answer-result.vue";
import ReaderPopup from "./slots/reader-popup.vue";
import BookCatalogTypeWrap from "./slots/book-catalog-type.vue";
import ChapterConfirmWrap from "./slots/publish-confirm.vue";
import AddCatalogTypeWrap from "./slots/add-catalog-type.vue";
import SendSuccessWrap from "./slots/send-success.vue";
import SendErrorWrap from "./slots/send-error.vue";
import RedPacketInCommentWrap from "./slots/red-packet-comment.vue";
import RedPacketInGlobalWrap from "./slots/red-packet-global.vue";
import RedPacketSendWrap from "./slots/red-packet-send.vue";
import MonthlyNotifyWrap from "./slots/monthly-notify.vue";
import RedPackStatusWrap from "./slots/RedPackStatus.vue";

let instance = null;

/* eslint-disable */
function errDialogConstruction(component) {
  const Component = Vue.extend(component);
  return function(params = {}) {
    if (instance) return;
    return new Promise((resolve, reject) => {
      instance = new Component({
        el: document.createElement("div"),
        data: Object.assign({ visible: true }, params),
        methods: {
          resolve,
          reject,
          close() {
            this.visible = false;
            resolve(this.$data);
          },
        },
        created() {
          console.log(this.$el);
        },
        destroyed() {
          try {
            window.removeEventListener("popstate", () => {
              console.log("移除监听器");
            });
            instance = null;
            if (this.$el.parentNode) {
              this.$el.parentNode.removeChild(
                document.querySelector(".van-overlay.yc-overlay")
              );
              this.$el.parentNode.removeChild(this.$el);
            }
          } catch (e) {
            reject(e);
          }
        },
      });
      console.log(instance)
      document.body.appendChild(instance.$el);
    });
  };
}

export const Chapter_Pay = errDialogConstruction(ChapterPay);
export const Chapter_Buy = errDialogConstruction(ChapterBuy);
export const Book_Pay = errDialogConstruction(BookPay);
export const Book_Buy = errDialogConstruction(BookBuy);
export const LoginNotice = errDialogConstruction(NoLogin);
export const Subscribe = errDialogConstruction(SubscribeDialog);
export const SubscribeInChapter = errDialogConstruction(SubscribeChapterDialog);
export const Custom = errDialogConstruction(CustomDialog);
export const WeekSign = errDialogConstruction(WeekSignDialog);
export const Activity = errDialogConstruction(ActivityDialog);
export const FiveOneActivity = errDialogConstruction(FiveOneActivityDialog);
export const EasyActivity = errDialogConstruction(EasyActivityDailog);
export const CrmActivity = errDialogConstruction(CrmActivityDialog);

export const AnswerResult = errDialogConstruction(AnswerResultDialog);
export const ReaderAward = errDialogConstruction(ReaderPopup);
export const BookCatalogTypeChooseWrap = errDialogConstruction(
  BookCatalogTypeWrap
);
export const ChapterPublishConfirmWrap = errDialogConstruction(
  ChapterConfirmWrap
);
export const AddCatalogType = errDialogConstruction(AddCatalogTypeWrap);

export const SendSuccess = errDialogConstruction(SendSuccessWrap);
export const SendError = errDialogConstruction(SendErrorWrap);
export const RedPacketInComment = errDialogConstruction(RedPacketInCommentWrap);
export const RedPacketInGlobal = errDialogConstruction(RedPacketInGlobalWrap);
export const RedPacketSend = errDialogConstruction(RedPacketSendWrap);

export const MonthlyNotify = errDialogConstruction(MonthlyNotifyWrap);
export const RedPackStatus = errDialogConstruction(RedPackStatusWrap);
