<template>
  <div class="x-empty">
    <img src="../../assets/empty.png" alt="">
  </div>
</template>

<script>
export default {
  name: "XEmpty"
}
</script>
