const tagColors = [
  {
    background: "#FFD8D8",
    color: "#FF6E6E"
  },
  {
    background: "#D8DAFF",
    color: "#9096FF"
  },
  {
    background: "#D8FFDA",
    color: "#6ECB73"
  },
  {
    background: "#FFF7D8",
    color: "#E8BF67"
  },
  {
    background: "#D8F3FF",
    color: "#65A2ED"
  }
];

export default tagColors;
