<template>
  <x-err-dialog :visible="visible"
                :class="{'qingrenjie': !isActive}">
    <div class="activity-dialog">
      <template v-if="isActive">
        <div class="activity-content">
          <div class="activity-banner">
            <img src="./imgs/active_banner.jpg"
                 alt="周卡">
            <span class="first-active">{{r.send}}奖金币已发放至账户，请查收~</span>
            <div class="activity-info">
              <p class="total">已累计领取：<i>{{r.add}}</i>奖金币</p>
              <p class="time">周卡有效期：{{r.start_time.substr(0,10)}} -- {{r.end_time.substr(0,10)}}</p>
            </div>
          </div>
          <div class="activity-main">
            <p class="activity-countdown">- {{r.limit === 0 ? `已过期` : `还有${r.limit}天过期`}} -</p>
            <div class="activity-week__list">
              <div class="week-list__one">
                <template v-for="(week, i) in r.options.slice(0, 4)">
                  <div :class="['week-item', { 'week-item__active': i < r.sign }]"
                       :key="i">
                    <div class="week-wrap">
                      <img :src="i < r.sign ? coinGot : coin"
                           alt="">
                      <span class="day-get">{{week.amount}}书币</span>
                    </div>
                    <span class="day-key">累计{{week.day}}天</span>
                  </div>
                </template>
              </div>
              <div class="week-list__two">
                <template v-for="(week, i) in r.options.slice(4)">
                  <div :class="['week-item', { 'week-item__active': (i + 4) < r.sign }]"
                       :key="i">
                    <div class="week-wrap">
                      <img :src="(i + 4) < r.sign ? coinGot : coin"
                           alt="">
                      <span class="day-get">{{week.amount}}书币</span>
                    </div>
                    <span class="day-key">累计{{week.day}}天</span>
                  </div>
                </template>
              </div>
            </div>
            <div class="activity-rules">
              <p class="rule">1.周卡有效期7天，记得每天来看书领奖金币哦；</p>
              <p class="rule">2.每日登录自动发放，请去个人中心查收。</p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="activity-content">
          <img :src="bg_banner"
               alt="">
          <span class="close-area"
                @click="close"></span>
        </div>
        <img src="./imgs/close_btn.png"
             alt=""
             class="close-btn"
             @click="normalClose">
      </template>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog,
  },
  data() {
    return {
      isActive: !!window.ycsd.week_card.is_activate,
      bg_banner: window.ycsd.week_card.img,
      coinGot: require("./imgs/get.png"),
      coin: require("./imgs/active_coin.png"),
    };
  },
  methods: {
    normalClose() {
      this.reject("close");
      this.close();
    },
  },
};
</script>

<style lang="scss">
.qingrenjie {
  background: transparent;

  .activity-dialog .activity-content .activity-click__area {
    bottom: 12%;
    width: 83%;
  }

  .to-activity {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 2.4rem;
  }

  .close-btn {
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 0.4rem;
  }

  .close-area {
    position: absolute;
    bottom: 13%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90%;
    height: 1rem;
  }
}
</style>
