/**
 * 表情
 */
const EMOJI_URL = "https://cdn.ycsd.cn/ycsd_web_3nd/images/em/";

const Emojis = {
  呵呵: "smilea",
  可怜: "kl",
  挤眼: "zy",
  泪: "sada",
  太开心: "mb",
  嘘: "x",
  打哈欠: "k",
  馋嘴: "cza",
  困: "sleepya",
  酷: "cool",
  晕: "dizzya",
  阴险: "yx",
  ok: "ok",
  赞: "z2",
  嘻嘻: "tootha",
  懒得理你: "ldln",
  衰: "cry",
  抱抱: "bba",
  挖鼻屎: "kbsa",
  闭嘴: "bz",
  偷笑: "heia",
  拜拜: "88",
  睡觉: "sleepa",
  花心: "hsa",
  悲伤: "bs",
  怒骂: "nm",
  耶: "ye",
  来: "come",
  哈哈: "laugh",
  可爱: "tza",
  吃惊: "cj",
  鄙视: "bs2",
  亲亲: "qq",
  右哼哼: "yhh",
  左哼哼: "zhh",
  生病: "sb",
  爱你: "lovea",
  害羞: "shamea",
  委屈: "wq",
  吐: "t",
  怒: "angrya",
  疑问: "yw",
  汗: "sweata",
  思考: "sk",
  钱: "money",
  失望: "sw",
  哼: "hatea",
  鼓掌: "gza",
  黑线: "h",
  抓狂: "crazya",
  心: "hearta",
  伤心: "unheart",
  good: "good",
  不要: "no",
  弱: "sad"
};

export function formatEmojis() {
  let emojis = [];
  Object.keys(Emojis).forEach(name => {
    emojis.push({
      name,
      key: Emojis[name],
      url: EMOJI_URL + Emojis[name] + "_thumb.gif"
    });
  });
  return emojis;
}

/**
 * 将带有[em=xxx]的表情内容 格式化成图片显示
 */
export function formatContentWithEmoji(contents) {
  contents = contents || "";
  let reg = new RegExp("\\[em=(.+?)\\]", "g");
  return contents.replace(reg, `<img src='${EMOJI_URL}$1_thumb.gif' />`);
}

// export default formatEmojis;
