import "./utils/mock";
import "./base/base.css";
import "./base/fonts/font.css";
import "./scss/index.scss";
import "./utils/rem";
import "./components";

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router/index";

// import fundebug from "fundebug-javascript";

// fundebug.apikey =
//   "b9322b29e91793bbdcd20678a99cdde70bde4e4ad12d9db3f715bfa6d4a4c911";

Vue.config.productionTip = false;
/* eslint-disable */
// if (process.env.NODE_ENV === "production") {
//   fundebug.setHttpBody = true;
//   // 事件过滤 过滤掉一些不必要的错误
//   fundebug.filters = window.errFilters;

//   // 不监控资源的加载错误
//   fundebug.silentResource = true;

//   function formatComponentName(vm) {
//     if (vm.$root === vm) return "root";

//     var name = vm._isVue
//       ? (vm.$options && vm.$options.name) ||
//         (vm.$options && vm.$options._componentTag)
//       : vm.name;
//     return (
//       (name ? "component <" + name + ">" : "anonymous component") +
//       (vm._isVue && vm.$options && vm.$options.__file
//         ? " at " + (vm.$options && vm.$options.__file)
//         : "")
//     );
//   }

//   Vue.config.errorHandler = function(err, vm, info) {
//     var componentName = formatComponentName(vm);
//     var propsData = vm.$options && vm.$options.propsData;

//     fundebug.notifyError(err, {
//       metaData: {
//         componentName: componentName,
//         propsData: propsData,
//         info: info
//       }
//     });
//   };
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
