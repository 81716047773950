const weekArr = [
  [
    require("@/assets/week_sign/week_1.png"),
    require("@/assets/week_sign/week_1_active.png")
  ],
  [
    require("@/assets/week_sign/week_2.png"),
    require("@/assets/week_sign/week_2_active.png")
  ],
  [
    require("@/assets/week_sign/week_3.png"),
    require("@/assets/week_sign/week_3_active.png")
  ],
  [
    require("@/assets/week_sign/week_4.png"),
    require("@/assets/week_sign/week_4_active.png")
  ],
  [
    require("@/assets/week_sign/week_5.png"),
    require("@/assets/week_sign/week_5_active.png")
  ],
  [
    require("@/assets/week_sign/week_6.png"),
    require("@/assets/week_sign/week_6_active.png")
  ],
  [
    require("@/assets/week_sign/week_7.png"),
    require("@/assets/week_sign/week_7_active.png")
  ]
];

export default weekArr;
