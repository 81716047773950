<template>
  <x-err-dialog :visible="visible">
    <div class="send-success-wrap">
      <template v-if="type === 'sendProps'">
        <div class="bg-wrap">
          <img src="./imgs/send-gift.png"
               alt="">
          <div class="back-info">
            <p class="send-item">感谢您的{{result.num || 0}}个<i>{{result.extra.giftName}}</i></p>
            <span class="back-exp">经验值+{{result.exp}}</span>
          </div>
        </div>
      </template>
      <template v-else-if="type === 'sendTickets'">
        <div class="bg-wrap">
          <img src="./imgs/send-month.png"
               alt="">
          <div class="back-info back-info-ticket">
            <p class="send-item">成功投出月票{{result.num || 0}}张</p>
            <span class="back-exp">经验值+{{result.exp}}</span>
          </div>
          <span class="click-area"
                @click="close"></span>
        </div>
      </template>
      <template v-else-if="type === 'sendRecomms'">
        <div class="bg-wrap">
          <img src="./imgs/send-rec.png"
               alt="">
          <div class="back-info back-info-ticket">
            <p class="send-item">成功投出推荐票{{result.num || 0}}张</p>
            <span class="back-exp">经验值+{{result.exp}}</span>
          </div>
          <span class="click-area"
                @click="close"></span>
        </div>
      </template>
      <div class="author-wrap">
        <div class="author-info">
          <img :src="result.author.avatar"
               :alt="result.author.nickname">
          <span>{{result.author.nickname}}</span>
        </div>
        <p class="author-text"
           v-html="result.content"></p>
      </div>
      <div class="send-operator-wrap">
        <p class="close"
           @click="close">关闭</p>
        <p class="continue"
           @click="continueVote">{{continueText}}</p>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  computed: {
    continueText() {
      return this.type === "sendProps" ? "继续送礼" : "继续投";
    }
  },
  methods: {
    continueVote() {
      this.$toast({
        type: "loading",
        message: "请稍等...",
        forbidClick: true,
        duration: 0
      });
      let type = this.type;
      let index = type === "sendProps" ? 0 : type === "sendTickets" ? 1 : 2;
      this.resolve({ action: "continue", index: index });
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.send-success-wrap {
  .bg-wrap {
    font-size: 0;
    position: relative;

    img {
      width: 100%;
    }

    .back-info {
      position: absolute;
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.32rem;
      color: #f7f7f7;
      display: flex;
      justify-content: center;
      align-items: cener;
      flex-direction: column;

      i {
        font-weight: bold;
      }

      span {
        line-height: 1;
        font-size: 0.3rem;
        padding: 0.12rem 0rem;
        background: rgba($color: #f7f7f7, $alpha: 0.2);
        border-radius: 0.6rem;
        margin-top: 0.2rem;
      }

      &.back-info-ticket {
        color: #333;
        transform: translate(-50%, -24%);

        span {
          color: #a0590d;
          background: rgba($color: #fff, $alpha: 0.3);
        }
      }
    }

    .click-area {
      position: absolute;
      width: 0.8rem;
      height: 0.8rem;
      right: 0.1rem;
      top: 0.1rem;
    }
  }

  .author-wrap {
    padding: 0.24rem;

    .author-info {
      font-size: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.2rem;
      }

      span {
        font-size: 0.28rem;
        color: #303030;
      }
    }

    .author-text {
      font-size: 0.26rem;
      color: #303030;
      // padding-left: 0.5rem;
      margin-top: 0.22rem;
      line-height: 1.8;
      text-align: left;
    }
  }

  .send-operator-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0.24rem 0.24rem;

    p {
      flex: 1;
      border: 1px solid #ccc;
      border-radius: 0.12rem;
      font-size: 0.28rem;
      color: #999;
      padding: 0.15rem 0;

      &:last-child {
        margin-left: 0.3rem;
        border-color: #ef5952;
        background: #ef5952;
        color: #fff;
        font-weight: bolder;
      }
    }
  }
}
</style>
