<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-09-18 11:54:43
 * @LastEditors: ZhengXiaowei
 * @LastEditTime: 2019-09-18 11:55:35
 * @Description: 阅读器内的强关弹窗
 -->
<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask>
    <div class="subscribe-dialog">
      <div class="subscribe-title"
           @click="close">
        <img :src="data.TitleImgSrc"
             alt="">
      </div>
      <div class="subscribe-content">
        <template v-if="data.Introduction.length">
          <p class="loop-text"
             v-for="(t, i) in data.Introduction"
             :key="i">{{t}}</p>
        </template>
        <img :src="data.BarcodeSrc"
             alt="">
        <p class="to-subscribe"
           v-show="data.showFooterText === undefined ? true : data.showFooterText">长按识别关注即可免费阅读</p>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  }
};
</script>

<style lang="scss" scoped>
.subscribe-title {
  position: relative;
  font-size: 0;

  img {
    width: 100%;
  }
}

.subscribe-content {
  font-size: 0;

  .loop-text {
    font-size: 0.3rem;
    color: #42311a;
    line-height: 2;

    &:first-child {
      margin-top: 0.2rem;
    }
  }

  img {
    width: 100%;
  }

  .to-subscribe {
    font-size: 0.3rem;
    color: #753736;
    padding: 0 0 0.4rem;
  }
}
</style>
