<template>
  <x-err-dialog :visible="visible">
    <div class="no-login__dialog">
      <p class="dialog-title van-hairline--bottom">提示</p>
      <div class="dialog-content">
        <p>检测到您当前还未登录，是否前往登录？</p>
      </div>
      <div class="dialog-footer">
        <p class="dialog-confirm-btn dialog-back-btn"
           @click="back">返回上一页</p>
        <p class="dialog-confirm-btn"
           @click="close">前往登录</p>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  methods: {
    back() {
      history.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-back-btn {
  background: #fff;
  color: #f34740;
}
</style>
