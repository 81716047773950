<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask>
    <div class="subscribe-dialog">
      <p class="dialog-title van-hairline--bottom">温馨提示</p>
      <div class="subscribe-content">
        <p>关注作者授权公众号继续阅读</p>
        <p>更多精彩内容等你哟~</p>
        <div style="position: relative; height: auto">
          <img v-if="head"
               class="head-img"
               :src="head">
          <img :src="src">
        </div>
        <p class="tap-text">长按上图识别二维码关注</p>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

import { getWeakSubscribeQR } from "@/api";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  data() {
    return {
      head: "",
      src: ""
    };
  },
  created() {
    getWeakSubscribeQR().then(r => {
      this.src = r.src;
      this.head = r.head_img;
    });
  }
};
</script>

<style lang="scss" scoped>
.subscribe-dialog {
  position: relative;
  font-size: 0;

  .dialog-title {
    color: #32a1ff;
    font-weight: bold;
    font-size: 0.36rem;
  }
}

.subscribe-content {
  font-size: 0;
  padding: 0.3rem;

  .loop-text {
    font-size: 0.3rem;
    color: #42311a;
    line-height: 2;

    &:first-child {
      margin-top: 0.2rem;
    }
  }

  p:nth-child(1) {
    font-size: 0.34rem;
    color: #ff6060;
    font-weight: bold;
    margin-bottom: 0.15rem;
  }

  p:nth-child(2) {
    color: #ff6060;
    font-size: 0.3rem;
  }

  .tap-text {
    font-size: 0.32rem;
  }

  img {
    height: 4rem;
    width: 4rem;
    margin: 0.3rem auto;
    display: block;
  }

  .head-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 0.8rem;
    width: 0.8rem;
    margin: 0;
    pointer-events: none;
  }

  .to-subscribe {
    font-size: 0.3rem;
    color: #753736;
    padding: 0 0 0.4rem;
  }
}
</style>
