import * as type from "./mutation-type";

export const recordRouterStack = ({ state, commit }, path) => {
  const originStack = state.routerStack;
  const result = originStack.filter((stack) => stack[0] === path[0]);
  if (!result.length) commit(type.RECORD_APP_ROUTER_STACK, path);
  // if (!state.routerStack.includes(path[0]))
};

export const removeRouterStack = ({ commit }) => {
  commit(type.REMOVE_APP_LAST_ROUTER);
};
