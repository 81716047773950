/*
 * @Author: ZhengXiaowei
 * @Date: 2019-01-09 14:06:31
 * LastEditors: 晓晓晓晓晓丶vv
 * LastEditTime: 2020-05-25 16:14:01
 * @Description: file content
 */
import Axios from "axios";
import axios from "./axios";
import actions from "./action";
import {
  // BookFormat,
  // LibraryFormat,
  CategoryFormat,
  paramsFormat,
  MyCommentFormat,
  formatRedData,
} from "./format";

const common = actions.common;

/**
 * 登录
 * @param {object} datas
 */
export const Login = (datas) => {
  let data = JSON.parse(datas);
  let url = window.ycsd.account_login_url;
  // d.password = window.md5(d.password);
  return axios.post(url, data);
};

/**
 * 获取礼物排行
 * @param {string}} bid
 * @param {string}} action list => 获取礼物列表， bookinfo => 获取详情礼物短数据
 */
export const getGiftRank = (bid) => {
  return axios("/gift/bookGiftRecord", { params: { bid } });
};

/**
 * 获取粉丝模块数据
 * @param {string} bid 书籍id
 */
export const getGiftRankList = (bid) => {
  return Promise.all([
    getGiftRank({ bid }),
    getGiftRank({ bid, action: common.forBook }),
  ]).then(([list, top]) => {
    return {
      top: top,
      lately: list.lately,
    };
  });
};

/**
 * 获取粉丝列表
 * @param {string}} bid
 * @param {string}} action list => 获取粉丝列表， bookinfo => 获取详情粉丝短数据
 */
export const getFansRank = (params = { bid: "", page: 1 }) => {
  return axios("/books/fans", { params });
};

/**
 * 获取用户自己的粉丝数据
 * @param {*} bid 书籍id
 */
export const getUserFansData = (bid) => {
  return axios("/books/userFans", { params: { bid } });
};

/**
 * 获取我的书架
 */
export const getShelfBooks = (page = 1) => {
  return axios("/shelf/myBookList", { params: { page } });
};

/**
 * 获取最近阅读
 */
export const getRecent = () => {
  return axios("/read/recentList");
};

/**
 * 获取充值列表
 * @param {boolean} action 是否老用户的列表 1: old=> 老用户 2: spcial=> 单章购买 3: common=> 其他
 */
export const getChargeList = (is_new, bid) => {
  return axios("/order/chargeList", { params: { is_new, bid } });
};

/**
 * 获取充值列表 -- 审计看
 * @param {boolean} action 是否老用户的列表 1: old=> 老用户 2: spcial=> 单章购买 3: common=> 其他
 */
export const getSjChargeList = (is_new, bid) => {
  return axios("/order/bigChargeList", { params: { is_new, bid } });
};

/**
 * 获取书库分类列表
 * @param {string} type 类型
 */
export const getCategory = () => {
  return axios("/books/library/category").then((r) => CategoryFormat(r));
};

/**
 * 获取书库列表
 * @param {object} params 筛选参数
 */
export const getLibraryList = (params = { page: 1 }) => {
  getLibraryList.source = Axios.CancelToken.source();
  return axios("/books/library", {
    cancelToken: getLibraryList.source.token,
    params,
  })
    .then((r) => {
      return {
        list: r.list,
        meta: r.meta,
      };
    })
    .catch((e) => {
      if (!Axios.isCancel(e)) return Promise.reject(e);
    });
};

/**
 * 获取用户月票信息
 */
export const getUserMonthTickets = (params) => {
  return axios("/user/ticket/month", { params });
};

/**
 * 获取用户推荐票信息
 */
export const getUserRecomTickets = (params) => {
  return axios("/user/ticket/recomm", { params });
};

/**
 * 获取用户账户信息
 */
export const getUserAccount = () => {
  return axios("/user/account");
};

/**
 * 获取用户奖金币记录
 */
export const getUserRewardRecord = (params) => {
  return axios("/user/rewards", { params });
};

/**
 * 获取用户过期奖金币
 */
export const getUserExpireRecord = (params) => {
  return axios("/user/rewards/expire", { params });
};

/**
 * 获取用户充值记录
 */
export const getUserRechargeRecord = (params) => {
  return axios("/user/charges", { params });
};

/**
 * 获取评论数据
 * @param {string} bid 书籍id
 * @param {string} action list => 获取评论列表 bookinfo => 获取详情评论短数据  reply => 获取评论回复
 * @param {string} tid 如果为回复的话 需要评论id
 */
export const getComments = (
  params = {
    bid: "",
    action: actions.comment.getList,
    order: 0,
    essence: false,
    page: 1,
  }
) => {
  let data = paramsFormat(params, {
    chapterId: params.cid || null,
    order: params.order || 0,
    essence: params.essence || 0,
    action: params.action ? params.action : actions.comment.getList,
  });
  return axios("/comment/getCommentList", { params: data });
};

/**
 * 获取评论详情
 * @param {string} tid 评论id
 * @param {*} action
 */
export const getCommentDetail = (commentId, bid) => {
  return axios("/comment/getCommentInfo", {
    params: { commentId, bid },
  }).then((r) => MyCommentFormat(r));
};

/**
 * 获取对应评论的回复列表
 * @param {string} bid 书籍id
 * @param {string} tid 评论id
 * @param {*} action
 */
export const getCommentReplyList = (
  params = {
    bid: "",
    tid: "",
    action: actions.comment.reply,
    page: 1,
  }
) => {
  let data = paramsFormat(params, {
    tid: params.tid,
    action: actions.comment.reply,
  });
  return axios("/api/book/BookReview.aspx", { params: data });
};

export const getMyCommnets = (params = { page: 1 }) => {
  return axios("/comment/getUserCommentList", { params });
};

/**
 * 获取作者信息
 * @param {number} id 作者id
 * @param {string} action 接口类型
 */
export const getAuthorInfo = (author_id, from) => {
  return axios("/author", { params: { author_id, from } });
};

/**
 * 获取书籍详情
 * @param {string} bid 书籍id
 */
export const getBookDetail = (bid) => {
  return Promise.all([axios(`/book/info?bid=${bid}`, { cache: false })]).then(
    ([book]) => {
      let {
        bookInfo,
        chapterList,
        bookData,
        commentList,
        author,
        gifts,
        fans,
        similar,
      } = book;
      return {
        detail: {
          book: bookInfo,
          isMonth: bookInfo.isMonth,
          is_on_shelf: bookInfo.isOnSelf,
          chapters: chapterList,
          lastReadCid: bookInfo.lastChapter,
        },
        bookData,
        gift: gifts,
        fans: fans,
        comments: commentList ? commentList : [],
        similar: similar,
        author: author ? author : {},
      };
    }
  );
};

/**
 * 获取个人中心
 */
export const getUserCenter = () => {
  return axios("/userinfo");
};

/**
 * 获取读者信息
 */
export const getReadersInfo = (uid) => {
  return axios("/reader", { params: { uid } });
};

/**
 * 获取读者送礼记录
 */
export const getReaderInteraction = (params = { page: 1, uid: "" }) => {
  return axios("/reader/interactive", { params });
};

/**
 * 获取读者评论
 */
export const getReaderComments = (params) => {
  return axios("/reader/topic", { params });
};

/**
 * 获取评论员详情
 */
export const getCommentorInfo = () => {
  return axios("/comment/getUserCenterCommentDetail");
};

/**
 * 按章订购
 * @param {*} params 分页信息
 */
export const getChapterOrder = (params) => {
  return axios("/user/chapterOrders", { params });
};

/**
 * 按本订购
 * @param {*} params 分页信息
 */
export const getBookOrder = (params) => {
  return axios("/user/bookOrders", { params });
};

/**
 * 按月订购
 * @param {*} params 分页信息
 */
export const getMonthOrder = (params) => {
  return axios("/user/bagOrders", { params });
};

/**
 * 获取用户的送礼记录
 * @param {*} params 分页信息
 */
export const getUserSendGiftRecord = (params) => {
  return axios("/user/gifts", { params });
};

/**
 * 获取章节列表
 * @param {string} bid 书籍id
 */
export const getChapterList = (params = { bid: "", isDraft: false }) => {
  let data = paramsFormat(params, {
    page: params.page_number,
    pageSize: 50,
    isDraft: params.isDraft || false,
  });
  return axios("/chapter/list", { params: data });
};

/**
 * 获取章节内容
 * @param {string} bid 书籍id
 * @param {string} cid 章节id
 */
export const getChapterContent = (bid, cid, page_from) => {
  return axios(`/chapter/info?bid=${bid}&cid=${cid}&page_from=${page_from}`, {
    cache: true,
  });
};

/**
 * 获取作者有话说
 * @param {string} bid 书籍id
 */
export const AuthorAnnouncement = (params = { bid: "", page: 1 }) => {
  let data = paramsFormat(params, {
    page: params.page,
  });
  return axios("/book/authorSay", { params: data });
};

/**
 * 获取我的消息
 */
export const Message = (params = { action: "GetList", page_number: 1 }) => {
  let data = paramsFormat(params, { page_number: params.page_number });
  return axios("/api/user/Message.aspx", { params: data });
};

/**
 * 阅读器内章节或者全书购买
 * @param {string} type 购买类型 1-章节 2-全本
 * @param {string} bid 书籍id
 * @param {string} cid 章节id
 */
export const buyBookOrChapter = ({ bid, cid }) => {
  let data = {
    bid,
    cid,
  };
  return axios.post("/subscribe", data);
};

/**
 * 获取排列分类
 *  @param {string} action 分类值
 */
export const getBookRank = (action) => {
  let url = `/books/rank/${action || "subscribe"}`;
  return axios(url);
};

/**
 * 搜索
 *  @param {string} action 分类值
 */
export const getSearch = (params = { action: "", searchkey: "" }) => {
  let data = paramsFormat(params, {
    page: params.page_number,
    key: params.searchkey,
  });
  return axios("/search", { params: data });
};

/**
 * 获取搜索热词
 */
export const getHotSearchKey = () => {
  return axios("/search/hot");
};

/**
 * 获取首页
 */
export const getHome = () => {
  return axios("/home/index");
};

/**
 * 发布评论
 * @param {object} params 评论参数
 */
export const publishComment = (data) => {
  return axios.post("/comment/postComment", data);
};

/**
 * 获取礼物列表
 */
export const getPorpsList = (bid) => {
  return axios("/gift/getGiftsList", { params: { bid } });
};

/**
 * 获取推荐票信息
 * @param {string} action 类型
 */
export const getTickets = (bid) => {
  return axios(`/ticket/recommendTicketInfo?bid=${bid}`);
};

/**
 * 获取月票信息
 * @param {*} bid 书籍id
 */
export const getMonthTickets = (bid) => {
  return axios(`/ticket/monthTicketInfo?bid=${bid}`);
};

/**
 * 加书签
 * @param {object} params 评论参数
 */
export const addBookCase = (bid) => {
  return axios("/shelf/bookAdd", {
    params: { bid },
  });
};

/**
 * 赠送礼物
 * @param {string} bid 书籍id
 * @param {string} icate 礼物id
 * @param {string} action
 */
export const readerSendGift = (params = { bid: "", gift_id: "" }) => {
  return axios("/gift/sendGifts", {
    params,
  });
};

/**
 * 送推荐票
 * @param {*} params
 */
export const readerSendTickets = (data = { bid: "", nums: "" }) => {
  return axios.post("/ticket/voteRecommendTicket", data);
};

/**
 * 送月票
 * @param {*} params
 */
export const readerSendMonthTickets = (params = { bid: "", nums: "" }) => {
  return axios("/ticket/voteMonthTicket", {
    params,
  });
};

/**
 * 获取我的粉丝等级
 */
export const getMyFansLev = (params) => {
  return axios("/user/fans", { params });
};

/**
 * 删除收藏
 */
export const delBookCase = (params = { bid: "" }) => {
  return axios("/shelf/bookDelete", { params });
};

/**
 * 获取包月有效列表
 */
export const getMonthlyPackageList = (type) => {
  return axios(`/bag/bagInfo?type=${type}`, { cache: false });
};

/**
 * 根据书包id获取对应的书籍信息
 * @param {string} bag_id 书包id
 */
export const getMonthlyPackageBooks = (bag_id) => {
  return axios(`/bag/get_book_bag_details?bag_id=${bag_id}`);
};

/**
 * 订购书包
 * @param {string} bag_id 书包id
 */
export const orderMonthlyPackage = (type) => {
  return axios(`/bag/buyBag?type=${type}`);
};

/**
 * 阅读器弱关
 */
export function getWeakSubscribeQR() {
  return axios("/subscribe/qrcode");
}

/**
 * 统计标签关键字
 * @param {string} tagName 标签关键字
 */
export const keyWordsStatistics = (tagName) => {
  return axios(`/search?key=${tagName}`);
};

export const LastPage = (bid) => {
  return axios("/books/readOverRecommend", {
    params: { bid },
  });
};

/**
 * 获取签到数据
 */
export const getSignInfo = () => {
  return axios("/activity/signInfo");
};

/**
 * 签到
 */
export const toSign = () => {
  return axios("/activity/sign");
};

// 周卡信息
export const weekCards = () => {
  return axios("/activity/weekCard");
};

/**
 * 获取答题活动的首页信息
 */
export const getQAInfo = () => {
  return axios("/activity/activityQuestion");
};

/**
 * 提交答案
 * @param {string} aid 问题答案
 */
export const uploadQA = (aid) => {
  return axios("/activity/replyQuestion", { params: { aid } });
};

/**
 * 获取答题周排行
 */
export const getQAWeekRank = () => {
  return axios("/activity/getActivityQuestionWeekRankBonus");
};

/**
 * 获取翻牌奖励
 */
export const getQABouns = () => {
  return axios("/activity/getActivityQuestionBonus");
};

/**
 * 上传投诉
 */
export const uploadReport = (params) => {
  return axios("/user/complaint", { params });
};

/**
 * 获取书籍详情
 * @param {string} bid 书籍id
 */
export const getAuthorBookDetail = (bid) => {
  return Promise.all([
    axios(`/author/book/info?bid=${bid}`, { cache: false }),
  ]).then(([book]) => {
    let {
      bookInfo,
      chapterList,
      bookData,
      commentList,
      author,
      gifts,
      fans,
      similar,
    } = book;
    return {
      detail: {
        book: bookInfo,
        isMonth: bookInfo.isMonth,
        is_on_shelf: bookInfo.isOnSelf,
        chapters: chapterList,
        lastReadCid: bookInfo.lastChapter,
      },
      bookData,
      gift: gifts,
      fans: fans,
      comments: commentList ? commentList : [],
      similar: similar,
      author: author ? author : {},
    };
  });
};

/**
 * 获取作者书籍管理中的章节列表
 * @param {object} params 参数
 */
export const getAuthorBookChapters = (params) => {
  return axios("/author/chapter/list", { params });
};

/**
 * 获取章节券列表
 */
export const getAuthorChapterVolumes = (params) => {
  return axios("/author/volumes/list", { params });
};

/**
 * 获取作者编辑的章节内容
 * @param {*} bid 书籍id
 * @param {*} cid 章节id
 * @param {*} uid 作者id
 */
export const getAuthorChapterEditContent = (params) => {
  return axios("/author/chapter/info", { params });
};

/**
 * 发布章节
 * @param {object} data 章节数据
 */
export const authorChapterPublish = (data) => {
  return axios.post("/author/chapter/publish", data);
};

/**
 * 更新章节
 * @param {object} data 章节数据
 */
export const authorChapterUpdate = (data) => {
  return axios.post("/author/chapter/update", data);
};

/**
 * 获取读者风云榜
 * @param {object} params 接口参数
 */
export const getRankTop = (params) => {
  return axios("/rank/user", { params });
};

/**
 * 帮助
 */
export const getHelpList = () => {
  return axios("/help");
};

/**
 * 获取经验值历史记录
 * @param {*} params
 */
export const getExperienceList = (params) => {
  return axios("/user/integralLogs", { params });
};

/**
 * 获取作者可发红包的余额
 */
export const getAuthorRedPacketBalance = () => {
  return axios("/redPack/redPacketCheckBalance");
};

/**
 * 作者送红包
 * @param {*} params 红包参数
 */
export const sendRedPacketByAuthor = (params) => {
  return axios("/redPack/makeCommentRedPacket", { params });
};

/**
 * 获取用户领取的红包列表
 * @param {*} params 参数
 */
export const getUserRedPacketList = (params) => {
  return axios("/redPack/getUserLogs", { params });
};

/**
 * 领取红包
 * @param {*} pack_id 红包id
 */
export const getRedPacketInComment = (pack_id) => {
  return axios("/redPack/receive", { params: { pack_id } });
};

/**
 * 作者红包入口信息
 */
export const showAuthorRedPacketInfo = () => {
  return axios("/redPack/redPacketAuthorEnter");
};

/**
 * 作者红包入口发放红包的记录
 * @param {*} params
 */
export const showAuthorRedPacketRecord = (params) => {
  return axios("/redPack/redPacketSendLog", { params });
};

/**
 * 作者领取红包
 * @param {*} bid 书籍id
 */
export const getAuthorRedPacket = (bid) => {
  return axios("/redPack/getRankRedPacketCoins", { params: { bid } });
};

/**
 * 作者一键领取所有红包
 */
export const getAuthorAllRedPacket = () => {
  return axios("/redPack/getAllRankRedPacketCoins");
};

/**
 * 或者作者领取红包的记录
 * @param {*} params
 */
export const getAuthorRedPacketRecord = (params) => {
  return axios("/redPack/getRankRedPacketCoinsLogs", { params });
};

/**
 * 获取本周互动榜
 * @param {*} params
 */
export const getWeekInteractList = (params) => {
  return axios("/redPack/getCommentRank", { params });
};

/**
 * 获取app支付参数
 * @param {*} params app支付参数
 */
export const getAppPayConfig = (params) => {
  return axios("/createOrder", { params });
};

/**
 * 红包广场数据
 * @param {*} params
 */
export const getRedBackgroundList = (params) => {
  return axios("/redPack/square", { params }).then((r) => {
    let list = r.list.map((item) => {
      return formatRedData(item);
    });
    return { list: list, meta: r.meta };
  });
};

/**
 * 创建章节红包
 * @param {*} params 参数
 */
export const createChapterRed = (params) => {
  return axios("/redPack/makeBookRedPacket", { params });
};

/**
 * 判断红包是否可领取
 * @param {*} pack_id 红包id
 */
export const isRedCanBePacked = (pack_id) => {
  return axios("/redPack/checkRedPacket", { params: { pack_id } });
};

/**
 * 红包领取详情
 * @param {*} pack_id 红包id
 */
export const getRedPackedInfo = (params) => {
  return axios("/redPack/awardList", { params });
};

/**
 * 获取首页
 */
export const getUserTeenagerInfo = () => {
  return axios("/user/userTeenagerInfo");
};
/**
 * 验证青少年模式密码
 * @param {*} pack_id 红包id
 */
export const getCheckTeenagerPwd = (data) => {
  return axios.post("/user/checkTeenagerPwd", data);
};
/**
 * 开关青少年模式
 * @param {*} pack_id 红包id
 */
export const getopenTeenager = (data) => {
  return axios.post("/user/openTeenager", data);
};

/**
 * 手机号登录
 * @param {*}
 */
export const phoneLogin = (datas) => {
  let data = JSON.parse(datas);
  return axios.post("/auth/phone_login", data, {
    baseURL: process.env.NODE_ENV == "development"? "/newApi" :'https://pubapi.ycsd.cn/api',
  });
};

/**
 * 发送验证码
 * @param {*}
 */
export const sendCodeApi = (data) => {
  return axios.post("/auth/sendSms", data, {
    baseURL: process.env.NODE_ENV == "development"? "/newApi" :'https://pubapi.ycsd.cn/api',
  });
};

//获取手机号绑定情况
export const getPhoneBindStatus = (data) => {
  return axios("/user/phoneBindInfo", data);
};

//绑定手机号
export const bindPhone = (data) => {
  return axios.post("/user/bindPhone", data);
};

//替换绑定手机号
export const replacePhone = (data) => {
  return axios.post("/user/changeBindPhone", data);
};

//修改登录密码
export const changePassword = (data) => {
  return axios.post("/user/account/changePassword", data);
};