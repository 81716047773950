import { is_WeChat } from "@/utils";

const state = {
  // * 1 = 微信浏览器, 0 = 其他浏览器
  browerType: is_WeChat(),
  router: "",
  show: false,
  login_url: "//publicapi.ycsd.cn/api/auth/account_login",
  dialog_answer_choose: false,
  isApp: false,
  routerStack: [],
};

export default state;
