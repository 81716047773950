const readerOptions = {
  // * 默认字体大小
  defaultFontSize: 19,
  // * 最大字体大小
  maxFontSize: 36,
  // * 最小字体大小
  minFontSize: 12,
  // * 背景集合
  bgColorStyles: [
    {
      background: "#e6e6e6",
      btnBorder: "#ccc",
      btnBackground: "#d9d9d9",
      sysBtnBorder: "#666",
      authorBackground: "#ededed",
      authorLineColor: "#e5e5e5",
      color: "#191919"
    },
    {
      background: "#cce8cf",
      btnBorder: "#95bc9a",
      btnBackground: "#b1d3b5",
      sysBtnBorder: "#95bc9a",
      authorBackground: "#dbefdd",
      authorLineColor: "#cde6d0",
      color: "#191919"
    },
    {
      background: "#f7f3e0",
      btnBorder: "#cec9ae",
      btnBackground: "#e8e3cb",
      sysBtnBorder: "#cec9ae",
      authorBackground: "#f9f7ed",
      authorLineColor: "#efecde",
      color: "#191919"
    },
    {
      background: "#e0eef7",
      btnBorder: "#a4c2d5",
      btnBackground: "#c7dce9",
      sysBtnBorder: "#a4c2d5",
      authorBackground: "#e9f3f9",
      authorLineColor: "#d3e4ee",
      color: "#191919"
    }
  ],
  // * 夜间模式背景数据
  nightStyle: {
    background: "#262626",
    btnBorder: "#444",
    btnBackground: "#333",
    sysBtnBorder: "#666",
    authorBackground: "#333",
    authorLineColor: "#444",
    color: "#666"
  }
};

export default readerOptions;
