<template>
  <div class="x-comment__container" style="padding: 0 .2rem">
    <s-comment-item v-for="i in 3" :key="i" class="van-hairline--bottom"></s-comment-item>
  </div>
</template>

<script>
export default {
  name: 'XSkeletonComment'
}
</script>
