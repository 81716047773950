<template>
  <div class="skeleton-pay">
    <div class="x-pay__top hr-bottom">
      <p class="s-skeleton__rect--biger"></p>
    </div>
    <div class="x-pay__box skeleton-part skeleton-part__box">
      <div class="s-skeleton__rect--big section-title"></div>
      <div class="x-pay__way--list">
        <div class="x-pay__way--item s-skeleton__rect--big"
             v-for="i in 2"
             :key="i">
        </div>
      </div>
      <div class="s-skeleton__rect--big section-title"></div>
      <div class="x-pay__list">
        <div class="x-pay__list--item s-skeleton__rect--biger"
             v-for="i in 4"
             :key="i"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "XSkeletonPay"
};
</script>

<style lang="scss" scoped>
@import "@/scss/components/skeleton/pay.scss";
</style>

