<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-01-09 14:06:31
 * @LastEditors: ZhengXiaowei
 * @LastEditTime: 2019-11-13 10:16:26
 * @Description: file content
 -->
<template>
  <van-dialog v-model="show"
              overlay-class="yc-overlay"
              :show-confirm-button="false"
              :close-on-click-overlay="closeOnClickMask">
    <slot></slot>
  </van-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    closeOnClickMask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    };
  },
  watch: {
    visible(val) {
      this.show = val;
    },
    show(v) {
      if (!v) {
        setTimeout(() => {
          this.$parent.$destroy();
          this.$parent.reject();
        }, 500);
      }
    }
  },
  mounted() {
    this.show = this.visible;
    window.addEventListener("popstate", () => {
      console.log("浏览器回退");
      this.show = false;
      this.$parent.reject("popstate");
    });
  }
};
</script>
