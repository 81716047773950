<template>
  <div class="skeleton-fans">
    <div class="x-fans__list">
        <div class="x-fans__list--item van-hairline--bottom"
             v-for="i in 8"
             :key="i">
          <div class="part-left">
            <p class="s-skeleton__circle"></p>
            <p class="s-skeleton__rect--small"></p>
          </div>
          <span class="s-skeleton__rect--small"></span>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "XSkeletonFans"
}
</script>

<style lang="scss" scoped>
@import "@/scss/components/skeleton/fans.scss";
</style>
