// * 记录路由地址
export const SET_ROUTER_PATH = "SET_ROUTER_PATH";

// * 设置登录状态
export const SET_LOGIN_STATUS = "SET_LOGIN_STATUS";

// * 设置浏览器类型
export const SET_BROWER_TYPE = "SET_BROWER_TYPE";

// * 开启全局弹窗
export const OPEN_DIALOG = "OPEN_DIALOG";

// * 关闭全局弹窗
export const CLOSE_DIALOG = "CLOSE_DIALOG";

// * 答题弹窗操作反馈
export const DIALOG_ANSWER_CHOOSE = "DIALOG_ANSWER_CHOOSE";

// * 记录app状态
export const RECORD_APP_STATUS = "RECORD_APP_STATUS";

// * 记录路由栈 - app中使用
export const RECORD_APP_ROUTER_STACK = "RECORD_APP_ROUTER_STACK";

// * 移除
export const REMOVE_APP_LAST_ROUTER = "REMOVE_APP_LAST_ROUTER";
