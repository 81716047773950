<template>
  <x-err-dialog class="red-pack-wrap"
                :visible="visible">
    <div class="red-pack-dialog">
      <img class="red-bg"
           :src="background" />
      <!-- 初始化状态 -->
      <div v-if="status === 'init'"
           class="initStatus">
        <img class="avatar"
             :src="avatar" />
        <p class="nickname">{{nickname}}</p>
      </div>
      <div v-else
           class="packStatus">
        <div class="user">
          <img :src="avatar" />
          <p>来自：{{nickname}}</p>
        </div>
        <div class="pack-info">
          <p class="first">{{packText.first}}</p>
          <p class="second"
             v-html="packText.second"></p>
        </div>
      </div>
      <span :class="status === 'init' ? 'click-area' : 'click-area click-area__text'"
            @click="tapRed"></span>
    </div>
    <img class="close"
         src="./imgs/close_btn.png"
         @click="visible = false" />
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";
import { getRedPacketInComment } from "@/api";
import { RedDetail } from "@/views";

const RED_BACKGROUND = {
  init: require("./imgs/init.png"),
  packed: require("./imgs/red-pack.png")
};

const RED_STATUS_TEXT = {
  failed: {
    first: "您来晚咯",
    second: "红包已经被人抢光了"
  },
  success: {
    first: "恭喜您",
    second: "获得<span style='font-size: .4rem; color: #ff6060'>@</span>书币"
  },
  packed: {
    first: "您已经抢过该红包",
    second: "获得<span style='font-size: .4rem; color: #ff6060'>@</span>书币"
  }
};

const RED_GONE = 30107;
const RED_PACKED = 30106;

export default {
  components: {
    "x-err-dialog": errDialog
  },
  data: () => ({
    background: RED_BACKGROUND.init,
    status: "init",
    packText: RED_STATUS_TEXT.success
  }),
  methods: {
    tapRed() {
      if (this.status === "init") {
        getRedPacketInComment(this.pack_id)
          .then(r => {
            this.background = RED_BACKGROUND.packed;
            this.status = "packed";
            this.packText = JSON.parse(JSON.stringify(RED_STATUS_TEXT.success));
            this.packText.second = this.packText.second.replace("@", r.coin);
          })
          .catch(e => {
            if (e.code === RED_GONE) {
              // 领完
              this.background = RED_BACKGROUND.packed;
              this.status = "packed";
              this.packText = RED_STATUS_TEXT.failed;
            } else if (e.code === RED_PACKED) {
              // 已经领取过
              this.background = RED_BACKGROUND.packed;
              this.status = "packed";
              this.packText = JSON.parse(
                JSON.stringify(RED_STATUS_TEXT.packed)
              );
              this.packText.second = this.packText.second.replace(
                "@",
                e.data.coin || 0
              );
            } else this.$toast(e.msg);
          });
      } else
        (this.visible = false),
          this.router.push({
            name: RedDetail.name,
            query: { pid: this.pack_id }
          });
    }
  }
};
</script>

<style lang="scss" scoped>
.red-pack-wrap {
  background: transparent;
  width: 70%;

  .close {
    width: 0.7rem;
    height: 0.7rem;
    margin-top: 0.3rem;
  }
}

.red-pack-dialog {
  font-size: 0;
  position: relative;

  .click-area {
    position: absolute;
    bottom: 12%;
    width: 68%;
    height: 0.8rem;
    left: 50%;
    transform: translate(-50%, 0);

    &__text {
      bottom: 3%;
      height: 0.7rem;
    }
  }

  .red-bg {
    width: 100%;
  }

  .initStatus {
    font-size: 0;
    position: absolute;
    left: 50%;
    top: 19.5%;
    transform: translate(-50%, 0);

    .avatar {
      width: 1.24rem;
      height: 1.24rem;
      border-radius: 50%;
    }

    .nickname {
      font-size: 0.36rem;
      color: #f7f7f7;
      font-weight: bold;
      margin-top: 0.25rem;
    }
  }

  .packStatus {
    .user {
      font-size: 0;
      position: absolute;
      left: 50%;
      top: 11%;
      transform: translate(-50%, 0);

      img {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
      }

      p {
        font-size: 0.24rem;
        color: #999;
        margin-top: 0.14rem;
      }
    }

    .pack-info {
      position: absolute;
      left: 50%;
      top: 42%;
      transform: translate(-50%, 0);
      width: 80%;
      text-align: center;

      p {
        font-size: 0.36rem;
        color: #333;
        line-height: 1.2;
      }

      .second {
        font-weight: bold;
      }
    }
  }
}
</style>