<template>
  <div class="skeleton-catalog">
    <div class="x-catalog__whole">
      <span class="s-skeleton__rect"></span>
      <span class="s-skeleton__rect"></span>
    </div>
    <div class="list">
      <p v-for="i in 20"
         :key="i"
         class="s-skeleton__rect--small"
         :style="{'width':`${(Math.random()*6+4)*10}%`}"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "XSkeletonCatalog"
};
</script>

<style lang="scss" scoped>
@import '@/scss/components/skeleton/catalog.scss';
</style>
