<template>
  <x-err-dialog :visible="visible"
                class="month-parent-bg">
    <div class="monthly-notify-dialog">
      <div class="book-cover">
        <div class="bg-blur"
             :style="bookStyle"></div>
        <div class="bg-blur__bg"></div>
        <div class="book-info">
          <img :src="month.cover"
               :alt="month.name" />
          <div class="book-name">
            <p>{{month.name}}</p>
            <span>作者：{{month.author}}</span>
          </div>
        </div>
      </div>
      <p class="book-intro"
         v-html="month.intro"></p>
      <p class="month-btn"
         @click="toMonthly">{{month.desc}}</p>
    </div>
    <img src="./imgs/close_btn.png"
         alt=""
         class="close-btn"
         @click="normalClose">
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  computed: {
    bookStyle() {
      return {
        background: `url(${this.month.cover}) top / cover no-repeat`
      };
    }
  },
  methods: {
    normalClose() {
      this.reject("close");
      this.close();
    },
    toMonthly() {
      location.href = this.link;
    }
  }
};
</script>

<style lang="scss" scoped>
.month-parent-bg {
  border-radius: 0.08rem 0.08rem 0 0;
  background: transparent;

  .close-btn {
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 0.4rem;
  }
}

.monthly-notify-dialog {
  position: relative;
  background: #fff;
  border-radius: 0.08rem;
  padding-bottom: 0.4rem;

  .book-cover {
    padding: 0.4rem 0.54rem;
    position: relative;
    overflow: hidden;

    .bg-blur {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      -webkit-filter: blur(20px);
      filter: blur(20px);
      z-index: 1;
      opacity: 0.5;
    }

    .bg-blur__bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      opacity: 0.6;
      z-index: 0;
    }
  }

  .book-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 99;

    img {
      width: 1.2rem;
      border-radius: 0.04rem;
      margin-right: 0.25rem;
    }

    .book-name {
      text-align: left;
    }

    p {
      font-size: 0.32rem;
      color: #333;
      font-weight: bold;
    }

    span {
      font-size: 0.22rem;
      color: #ee5750;
      line-height: 1;
      padding: 0.03rem 0.15rem;
      border: 1px solid #ee5750;
      border-radius: 0.4rem;
    }
  }

  .book-intro {
    padding: 0.4rem 0.24rem;
    font-size: 0.24rem;
    color: #333;
    text-align: left;
    line-height: 1.8;
  }

  .month-btn {
    width: 3.5rem;
    line-height: 0.7rem;
    border-radius: 0.35rem;
    background: #ee5750;
    text-align: center;
    color: #fff;
    font-size: 0.3rem;
    margin: 0 auto;
  }
}
</style>