<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-09-27 14:41:19
 * @LastEditors: 晓晓晓晓晓丶vv
 * @LastEditTime: 2020-04-29 09:40:42
 * @Description: 阅读器满足阅读字数弹窗
 -->
<template>
  <x-err-dialog :visible="visible"
                class="reader-popup">
    <div class="reader-popup__content">
      <!-- <img src="../../../assets/close.png"
           alt=""
           @click="close">-->
      <img src="./imgs/reader-popup_bg.png" />
      <div class="text-content">
        <p class="title-small">恭喜您今天看了<i>{{info.num}}</i>字</p>
        <p class="title-big">可以领取<i>{{info.coin}}</i>书币哦</p>
        <!-- <p class="to-get"
           @click="toActivity">立即领取</p> -->
      </div>
      <span class="click-area"
            @click="toActivity"></span>
    </div>
    <img src="./imgs/close_btn.png"
         alt=""
         class="close-btn"
         @click="normalClose">
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  methods: {
    toActivity() {
      location.href = this.info.link;
    },
    normalClose() {
      this.reject("close");
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.reader-popup {
  background: transparent;

  .close-btn {
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 0.4rem;
  }
}

.reader-popup__content {
  font-size: 0;
  text-align: right;
  // padding: 0.2rem;
  position: relative;

  // img {
  //   width: 0.4rem;
  //   height: 0.4rem;
  // }

  // .text-content {
  //   font-size: 0.34rem;
  //   color: #333;
  //   text-align: center;
  //   font-weight: bold;

  //   i {
  //     color: #ff5027;
  //     font-weight: bolder;
  //     font-size: 0.42rem;
  //   }

  //   .title-big {
  //     font-size: 0.38rem;
  //   }

  //   .to-get {
  //     color: #fff;
  //     font-size: 0.3rem;
  //     text-align: center;
  //     width: 60%;
  //     margin: 0.8rem auto 0;
  //     background: #ff5027;
  //     border-radius: 40px;
  //     padding: 0.2rem 0;
  //   }
  // }

  img {
    width: 100%;
  }

  .text-content {
    color: #5f3c31;
    font-size: 0.4rem;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 100%;

    i {
      color: #d94301;
    }

    .title-big {
      font-size: 0.3rem;
    }
  }

  .click-area {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 70%;
    height: 1rem;
  }
}
</style>