/**
 * 标签颜色
 */
import colors from "@/views/options/color.js";
import { keyWordsStatistics } from "@/api";

export const tagColors = {
  data() {
    return {
      tagColors: colors || []
    };
  },
  methods: {
    statistics(label) {
      keyWordsStatistics(label).then(() => {
        this.$router.push({
          path: "/SearchCenter",
          query: {
            key: label
          }
        });
      });
    }
  }
};
