/*
 * @Author: ZhengXiaowei
 * @Date: 2019-01-09 14:06:31
 * LastEditors: 晓晓晓晓晓丶vv
 * LastEditTime: 2020-06-09 14:40:31
 * @Description: 组件入口文件
 */
import Vue from "vue";

// 第三方库
import {
  Button,
  Lazyload,
  Stepper,
  Icon,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  List,
  PullRefresh,
  ActionSheet,
  Toast,
  Popup,
  Dialog,
  Swipe,
  SwipeItem,
  SwipeCell,
  Picker,
  Collapse,
  CollapseItem,
  DatetimePicker,
  Notify,
  Divider,
  Overlay,
  PasswordInput,
  NumberKeyboard,
  Cell, 
  CellGroup
} from "vant";

// import FastClick from "fastclick";

// 骨架屏组件
import "./skeleton";

// 本地组件
import XBook from "./book/book.vue";
import XPage from "./page/page.vue";
import XMenu from "./menu/menu.vue";
import XComment from "./comment/comment.vue";
import XPushComment from "./push-comment/push-comment.vue";
import XFooter from "./footer/footer.vue";
import XEmpty from "./empty/empty.vue";
import XEmoji from "./emoji/emoji.vue";
import { jumpBookInfoByApp } from "@/utils/app_register";

Vue.use(Lazyload, {
  loading: "https://cdn.ycsd.cn/ycsd_web_3nd/images/book/loading.png",
})
  .use(PasswordInput)
  .use(NumberKeyboard)
  .use(Button)
  .use(Stepper)
  .use(Icon)
  .use(RadioGroup)
  .use(Radio)
  .use(Tab)
  .use(Tabs)
  .use(List)
  .use(PullRefresh)
  .use(ActionSheet)
  .use(Toast)
  .use(Divider)
  .use(Popup)
  .use(Dialog)
  .use(Swipe)
  .use(SwipeItem)
  .use(SwipeCell)
  .use(Picker)
  .use(Collapse)
  .use(CollapseItem)
  .use(DatetimePicker)
  .use(Notify)
  .use(Cell)
  .use(CellGroup)
  .use(Overlay);

// FastClick.attach(document.body);

const isApp = !!window.ycsd.is_app;

Vue.use(XBook, {
  click(book) {
    if (isApp) jumpBookInfoByApp(book.bid);
    else location.href = `/detail/${book.bid}`;
    // this.$router.push(`/detail/${book.bid}`);
  },
});

Vue.component(XPage.name, XPage);
Vue.component(XMenu.name, XMenu);
Vue.component(XComment.name, XComment);
Vue.component(XPushComment.name, XPushComment);
Vue.component(XFooter.name, XFooter);
Vue.component(XEmpty.name, XEmpty);
Vue.component(XEmoji.name, XEmoji);
