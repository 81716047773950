<template>
  <div class="x-rank skeleton-rank">
    <div class="x-rank__menu">
      <p class="x-rank__menu--item s-skeleton__rect--small"
         v-for="i in 8"
         :key="i"></p>
    </div>
    <div class="x-rank__list">
      <s-book-multi class="x-rank__book van-hairline--bottom"
                    v-for="i in 8"
                    :key="i"></s-book-multi>
    </div>
  </div>
</template>

<script>
export default {
  name: "XSkeletonRank"
};
</script>

<style lang="scss" scoped>
@import "@/scss/components/skeleton/rank.scss";
</style>

