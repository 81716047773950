<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-02-21 11:54:38
 * @LastEditors: 晓晓晓晓晓丶vv
 * @LastEditTime: 2020-04-23 17:16:52
 * @Description: 书籍组件
 -->
<template>
  <div class="x-book"
       :style="multi || hideCover ? '' : `width: ${coverStyle.width}`"
       @click.stop="click(book)">
    <div v-if="!hideCover"
         :class="{'x-book__multi':multi}">
      <div class="x-book__coverbox">
        <img v-if="book.cover"
             class="x-book__cover"
             :style="coverStyle"
             v-lazy="book.cover"
             lazy="loading">
        <img v-else
             class="x-book__cover"
             :style="coverStyle"
             src="https://cdn.ycsd.cn/ycsd_web_3nd/images/book/loading.png">
        <div v-if="$route.name==='BookRank'&&book.bookindex<4"
             class="x-book__coverindex">
          <img src="../../assets/rank_mark.png"
               alt="">
          <span>{{book.bookindex}}</span>
        </div>
        <slot name="shadow"></slot>
      </div>
      <div v-if="multi"
           class="x-book__text--multi"
           :style="textStyle">
        <slot></slot>
      </div>
      <div v-else
           class="x-book__text--normal">
        {{book.name || book.book_name||book.booktitle}}
      </div>
    </div>
    <div v-else
         class="x-book__nocover">
      <div class="x-book__text--multi">
        <p class="x-book__name">
          <span class="x-book__titleName">{{book.name || book.book_name||book.booktitle}}</span>
          <template v-for="(l, i) in (book.keyword && book.keyword.split(',').slice(0,2)) || []">
            <span class="x-book__tag"
                  :key="i"
                  v-if="l"
                  @click.stop="statistics(l)">{{l}}</span>
          </template>
        </p>
        <p class="x-book__intro"
           v-html="book.intro.slice(0, 50) + '...'"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { tagColors } from "@/mixin/tagColors.js";
import { jumpBookInfoByApp } from "../../utils/app_register";

export default {
  props: {
    book: {
      // {name,cover}
      required: true,
      type: Object
    },
    multi: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "2rem"
    },
    prevent: {
      type: Boolean,
      default: false
    },
    hideCover: {
      type: Boolean,
      default: false
    }
  },
  mixins: [tagColors],
  name: "XBook",
  data() {
    const size = this.width.match(/[\d-.]+/g);
    const unit = this.width.match(/[^\d-.]+/g);
    return {
      coverStyle: {
        width: size + unit,
        height: (size / 3) * 4 + unit
      },
      textStyle: {
        width: `calc(100% - ${size + unit})`,
        height: (size / 3) * 4 + unit
      }
    };
  },
  methods: {
    click(book) {
      if (!this.prevent) {
        const isApp = !!window.ycsd.is_app;
        if (isApp) jumpBookInfoByApp(book.bid);
        else location.href = `/detail/${book.bid}`;
        // location.href = `/detail/${book.bid}`;
      }
      this.$emit("click", book);
    }
  },
  install(Vue, options) {
    //可以传入click事件
    if (options && typeof options.click === "function") {
      this.methods.customClick = options.click;
    }
    Vue.component(this.name, this);
  }
};
</script>
