<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask>
    <div class="chapter-pay-dialog">
      <p class="dialog-title van-hairline--bottom">全本购买<span class="close"
              @click.stop="visible=false"></span></p>
      <div class="dialog-content">
        <div class="chapter-info">
          <p>《{{bookName}}》</p>
        </div>
        <div class="chapter-price">
          <p>本书价格：<i>{{price}}</i>书币<span class="strong">（余额不足）</span></p>
          <p>余额：<i>{{originCoin}}</i>书币 <i>{{awardCoin}}</i>奖金币</p>
        </div>
      </div>
      <div class="dialog-footer">
        <p class="dialog-confirm-btn"
           @click="close">余额不足，去充值</p>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  }
};
</script>
