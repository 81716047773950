export function getviplevel(level) {
  let img;
  level < 1
    ? (img = "https://cdn.ycsd.cn/ycsd_web_3nd/images/assets/vip/普通vip.png")
    : level === 1
      ? (img = "https://cdn.ycsd.cn/ycsd_web_3nd/images/assets/vip/初级vip.png")
      : (img =
          "https://cdn.ycsd.cn/ycsd_web_3nd/images/assets/vip/高级vip.png");
  return img;
}

export function getaccountlevel(level) {
  level ? "" : (level = 1);
  let img;
  img =
    "https://cdn.ycsd.cn/ycsd_web_3nd/images/assets/user_big/个人中心-lv-" +
    level +
    ".png";
  return img;
}

export function getTicketLevel(level) {
  level = parseInt(level);
  let img;
  level < 1
    ? (img = "https://cdn.ycsd.cn/ycsd_web_3nd/images/assets/user/普通.jpg")
    : level === 1
      ? (img = "https://cdn.ycsd.cn/ycsd_web_3nd/images/assets/user/初级.jpg")
      : (img = "https://cdn.ycsd.cn/ycsd_web_3nd/images/assets/user/高级.jpg");
  return img;
}

export function getAuthorLevel(level) {
  level ? "" : (level = 1);
  let img;
  img =
    "https://cdn.ycsd.cn/ycsd_web_3nd/images/assets/author/" +
    level +
    "段作者.png";
  return img;
}

export function getFansLevel(lvName = "见习") {
  return `https://cdn.ycsd.cn/ycsd_web_3nd/images/assets/fans/${lvName}.png`
}
