<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask
                class="answer-dialog__wrap">
    <div class="answer-dialog__content">
      <img :src="isRight ? bg.right : bg.error"
           alt=""
           class="bg-top">
      <div :class="['ar-wrap', {'ar-wrap__error': !isRight}]">
        <p class="error-notice"
           v-if="!isRight">虽然答错了 但还是有奖励给您哦~</p>
        <p class="small-notice">{{isRight ? `共花了${cost}` : "正确答案 “"+ answer +"”"}}</p>
        <div class="ar-cards__wrap">
          <template v-for="(c, i) in cards">
            <div :class="['ar-card', {'card-rotate': isDrawing}]"
                 :key="i"
                 @click="draw(i)">
              <template v-if="!isClick">
                <img class="card-front"
                     v-lazy="bg.card_close"
                     alt="">
              </template>
              <template v-else>
                <div class="card-back">
                  <img v-lazy="i === select ? bg.card_check : bg.card_open"
                       alt="">
                  <div :class="['card-award', {'card-award__select': i === select}, {'card-award__bouns': c.bonus_attach}]">
                    <p class="card-get">{{c.bonus}}</p>
                    <span class="card-text">奖金币</span>
                    <p class="card-bouns" v-if="c.bonus_attach">
                      <span>+加成</span>
                      <i>{{c.bonus_attach}}奖金币</i>
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
// 数组随机排序
function shuffle(arr) {
  let len = arr.length;
  for (let i = 0; i < len - 1; i++) {
    let index = parseInt(Math.random() * (len - i));
    let temp = arr[index];
    arr[index] = arr[len - i - 1];
    arr[len - i - 1] = temp;
  }
  return arr;
}

// 奖励金额池
const AWARDS_POLL = [
  {
    bonus: "10"
  },
  {
    bonus: "15"
  },
  {
    bonus: "20"
  },
  {
    bonus: "25"
  },
  {
    bonus: "30"
  },
  {
    bonus: "100"
  },
  {
    bonus: "200"
  },
  {
    bonus: "500"
  },
  {
    bonus: "1000"
  },
  {
    bonus: "2000"
  }
]

import errDialog from "../err-dialog";

import { getQABouns } from "@/api";

import store from "@/store";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  data() {
    return {
      bg: {
        right: require("@/assets/answer/answer-right_title.png"),
        error: require("@/assets/answer/answer-error_title.png"),
        card_close: require("@/assets/answer/close.jpg"),
        card_open: require("@/assets/answer/open.jpg"),
        card_check: require("@/assets/answer/check_open.jpg")
      },
      awards: AWARDS_POLL,
      cards: new Array(6),
      select: null,
      isClick: false,
      isDrawing: false
    };
  },
  methods: {
    draw(i) {
      if (this.isClick) return;
      getQABouns().then(r => {
        this.temp = r;
        return this.findAndRemove(r);
      }).then(_ => {
        return this.arrShuffle(i, this.temp);
      }).then(_ => {
        this.select = i;
        this.isDrawing = true;
        store.commit("system/DIALOG_ANSWER_CHOOSE");
        setTimeout(() => {
          this.isClick = true;
        }, 150);
      });
    },
    findAndRemove(target) {
      return new Promise(resolve => {
        // 找到并移除
        let index = this.awards.findIndex(a => +a.bonus === target.bonus);
        this.awards.splice(index, 1);
        resolve();
      })
    },
    arrShuffle(index, obj) {
      return new Promise(resolve => {
        // 数组重排
        this.cards = shuffle(this.awards.slice(0, 5));
        this.cards.splice(index, 0, obj);
        resolve();
      });
    }
  }
};
</script>