<template>
  <div class="skleton-comment__detail">
    <s-comment-item class="hr-bottom" style="padding: 0 .2rem;"></s-comment-item>
    <div class="x-reply__item van-hairline--bottom skeleton-detail" v-for="i in 3" :key="i">
      <div class="s-skeleton__circle"></div>
      <div class="x-reply__item--user">
        <p class="x-reply__item--time">
          <span class="s-skeleton__rect--small"></span>
          <span class="s-skeleton__rect--small"></span>
        </p>
        <p class="s-skeleton__rect--small"></p>
        <p class="s-skeleton__rect--small" style="width:70%;"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "XSkeletonCommentDetail"
};
</script>

<style lang="scss" scoped>
@import '@/scss/components/skeleton/comment-detail.scss';
</style>
