/* eslint-disable */
const str = window.localStorage;

const state = {
  fontSize: str.getItem("fontSize") || 20,
  bgIndex: str.getItem("bgIndex") || 0,
  night: str.getItem("night") || false
};

export default state;
