import router from "../router";
import store from "../store";
import axios from "axios";
import Cache from "../utils/cache";
import { cookie } from "../utils";
import { appLogin } from "@/utils/app_register";

let instance = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

// 接口缓存
let cache = new Cache(axios);

cache.use({
  expire: 300000,
  instance,
  requestConfigFn: (config) => {
    if (config.header) {
      config.header.token = "test token";
    }
    console.log(config,'22')
    return Promise.resolve(config);
  },
  responseConfigFn: (res) => {
    if (res.data.code === 10001) {
      // * 未登录或者登录过期
      // * 清除原来的cookie
      // * 把地址存入cookie 方便后台授权登录后返回
      let r = store.state.system.router;
      let is_app = store.state.system.isApp;
      cookie.setCookie("N_loginUrl", window.location.origin + r);
      if (r.indexOf("/personalcenter") === -1) {
        if (is_app) {
          router.back();
          appLogin();
          return;
          // throw Error("to app login");
        } else {
          router.push({
            path: "/login",
            query: { redirect: r },
          });
        }
      }
    } else if (res.data.code === 10018) {
      // * 书籍不存在或者下架了 跳转到对应错误页面
      if (res.data.data.url) location.replace(res.data.data.url);
      else router.replace("/undershelf");
      return Promise.reject(res.data);
    }
    return Promise.resolve(res);
  },
});

export default instance;
