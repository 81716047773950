<template>
  <div class="skeleton-reader"
       :style="readerStyle">
    <div class="x-reader__title van-hairline--bottom">
      <p class="s-skeleton__rect--small title"
         :style="textStyle"></p>
      <div class="s-skeleton__rect--small"
           :style="textStyle"></div>
    </div>
    <div class="x-reader__operator">
      <div class="x-reader__operator--base">
        <span class="operator-item s-skeleton__rect--small"
              :style="textStyle"></span>
        <span class="operator-item s-skeleton__rect--small"
              :style="textStyle"></span>
        <span class="operator-item s-skeleton__rect--small"
              :style="textStyle"></span>
      </div>
      <div class="x-reader__operator--other">
        <span class="operator-item s-skeleton__rect--small"
              :style="textStyle"></span>
        <!-- <span class="operator-item s-skeleton__rect--small"></span> -->
      </div>
    </div>
    <div class="x-reader__content">
      <div class="box"
           v-for="i in 3"
           :key="i">
        <p class="s-skeleton__rect--small"
           :style="textStyle"
           v-for="i in 4"
           :key="i"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ReaderOptions from "@/views/options/reader.js";

export default {
  name: "XSkeletonReader",
  computed: {
    bgItem() {
      return this.options.bgColorStyles[this.bgIndex];
    },
    nightItem() {
      return this.options.nightStyle;
    },
    readerStyle() {
      return {
        background: this.night
          ? this.nightItem.background
          : this.bgItem.background
      };
    },
    textStyle() {
      return {
        background: this.night
          ? this.nightItem.btnBackground
          : this.bgItem.btnBackground,
        borderColor: this.night
          ? this.nightItem.btnBorder
          : this.bgItem.btnBorder
      };
    },
    ...mapGetters("reader", ["bgIndex", "night"])
  },
  data() {
    return {
      options: ReaderOptions
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/components/skeleton/reader.scss";
</style>

