<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask
                class="easy-activity">
    <div class="easy-activity-dialog">
      <div class="activity-content">
        <template v-if="isActived">
          <img src="./imgs/2020_dialog.png"
               alt="">
          <p class="text">
            <i>成功领取</i>
            <span>{{coin}}书币</span>
          </p>
          <span class="activity-click__area"
                @click="toActivity"></span>
        </template>
        <template v-else>
          <img src="./imgs/2020_unactive_dialog.jpg"
               alt="">
          <img class="btn"
               src="./imgs/2020_unactive_btn.png"
               alt=""
               @click="toActivity">
          <span class="activity-click__area-unactive"
                @click="close"></span>
        </template>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  computed: {
    isActived() {
      return this.info.extra.cardGainCoin > 0;
    },
    coin() {
      return this.info.extra.cardGainCoin || 0;
    }
  },
  methods: {
    toActivity() {
      location.href = this.info.link;
    }
  }
};
</script>

<style lang="scss" scoped>
.easy-activity {
  background: transparent;

  .activity-content {
    font-size: 0;
    position: relative;

    img {
      width: 100%;
    }

    .btn {
      width: 3.54rem;
      margin-top: 0.26rem;
    }

    .text {
      position: absolute;
      top: 58%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 100%;
      color: #fff;
      font-size: 0.28rem;
      font-weight: bold;
      line-height: 1;

      span {
        display: block;
        font-size: 0.4rem;
        margin-top: 0.2rem;
      }
    }
  }

  .activity-click__area {
    position: absolute;
    bottom: 9%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 84%;
    height: 1rem;
  }

  .activity-click__area-unactive {
    position: absolute;
    top: 0.14rem;
    right: 0.14rem;
    width: 0.6rem;
    height: 0.6rem;
  }
}
</style>