<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask
                class="red-packet-dialog__wrap">
    <div class="red-packet-content">
      <img src="./imgs/comment-red-packet-bg.png"
           alt="">
      <p class="user-get-number">获得<i>{{info.coin || 0}}</i>作者红包</p>
      <div class="author-comment">
        <div class="author-info">
          <img :src="info.author.avatar || defaultAvatar"
               alt="">
          <span>{{info.author.nickname}}</span>
        </div>
        <p class="comment">{{info.author.comment}}</p>
      </div>
      <span class="close-area"
            @click="close"></span>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  data: () => ({
    defaultAvatar: require("../../../assets/1.png")
  })
};
</script>

<style lang="scss" scoped>
.red-packet-dialog__wrap {
  .red-packet-content {
    font-size: 0;
    position: relative;

    img {
      width: 100%;
    }

    .user-get-number {
      font-size: 0.36rem;
      color: #6a3610;
      position: absolute;
      width: 100%;
      top: 26%;
      left: 50%;
      transform: translate(-50%, 0);

      i {
        font-size: 0.48rem;
        color: #ff2d2d;
        font-weight: bold;
      }
    }

    .author-comment {
      position: absolute;
      width: 92%;
      bottom: 16%;
      left: 50%;
      transform: translate(-48%, 0);

      .author-info {
        font-size: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 0.8rem;
          border-radius: 50%;
          margin-bottom: 0.07rem;
        }

        span {
          font-size: 0.26rem;
          color: #7e3d1a;
        }
      }

      .comment {
        font-size: 0.3rem;
        color: #8c431b;
        text-align: left;
        margin: 0.5rem 0.2rem 0;
      }
    }

    .close-area {
      position: absolute;
      width: 0.6rem;
      height: 0.6rem;
      right: 0;
      top: 0;
    }
  }
}
</style>