<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-11-13 11:24:25
 * @LastEditors: ZhengXiaowei
 * @LastEditTime: 2019-11-13 11:43:25
 * @Description: 添加卷
 -->
<template>
  <x-err-dialog :visible="visible">
    <div class="add-catalog-type-wrap">
      <p class="title">添加编辑卷</p>
      <div class="content">
        <div class="content-item">
          <span class="label">卷名</span>
          <div class="content-box">
            <input type="text"
                   placeholder="请输入卷名">
          </div>
        </div>
        <div class="content-item">
          <span class="label">顺序</span>
          <div class="content-box">
            <input type="number"
                   placeholder="输入序号">
            <span class="notice">(数字0-100)</span>
          </div>
        </div>
        <div class="content-item">
          <span class="label">简介</span>
          <div class="content-box">
            <textarea name="intro"
                      placeholder="请输入简介..."></textarea>
          </div>
        </div>
      </div>
      <div class="add-operator">
        <p class="operator-item"
           @click="close">取消</p>
        <p class="operator-item operator-sure"
           @click="close">确定</p>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  }
};
</script>

<style lang="scss" scoped>
.add-catalog-type-wrap {
  .title {
    font-size: 0.3rem;
    color: #666;
    height: 1.1rem;
    line-height: 1.1rem;
    text-align: center;
    border-bottom: 1px solid #f7f7f7;
  }

  .content {
    padding: 0.3rem;

    .content-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.4rem;

      .label {
        font-size: 0.26rem;
        color: #333;
        margin-right: 0.3rem;
        flex-shrink: 0;
        white-space: nowrap;
      }

      .content-box {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          flex: 1;
          font-size: 0.26rem;
          color: #666;
          background: #f7f7f7;
          border: none;
          outline: none;
          padding: 0 0.24rem;
          height: 0.7rem;
          line-height: 0.7rem;
          border-radius: 0.04rem;
        }

        textarea {
          flex: 1;
          font-size: 0.26rem;
          color: #666;
          background: #f7f7f7;
          border: none;
          outline: none;
          resize: none;
          padding: 0.24rem;
          border-radius: 0.04rem;
        }

        .notice {
          width: 1.6rem;
          font-size: 0.26rem;
          color: #999;
          // margin-left: 0.4rem;
        }
      }
    }
  }

  .add-operator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.3rem 0.3rem;

    .operator-item {
      flex: 1;
      height: 0.8rem;
      line-height: 0.8rem;
      background: #e6e6e6;
      border-radius: 0.04rem;
      color: #999;
      font-size: 0.28rem;

      &.operator-sure {
        color: #fff;
        background: #ee5750;
        margin-left: 0.2rem;
      }
    }
  }
}
</style>