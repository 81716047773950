<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-04-30 10:47:27
 * @LastEditors: 晓晓晓晓晓丶vv
 * @LastEditTime: 2020-10-22 13:55:31
 * @Description: file content
 -->
<template>
  <x-err-dialog :visible="visible"
                class="activity-dialog__wrap">
    <div class="activity-dialog">
      <div class="activity-content five-activity">
        <img src="./imgs/2020_1022_bg.png"
             alt="">
        <div class="show-wrap-content">
          <p class="date">{{today}}</p>
          <p class="text">
            <span>今日累计订阅</span>
            <span class="coin">{{global.extra.subCoinData.subCoin || 0}}书币</span>
          </p>
          <p class="waiting-send">待赠送{{global.extra.subCoinData.giveCoin || 0}}奖金币</p>
          <p class="go-ahead"
             v-if="global.extra.subCoinData.subCoin < 600">再订阅{{global.extra.subCoinData.needCoin || 0}}书币就可以送{{global.extra.subCoinData.minConsumeCoin || 0}}奖金币了哦</p>
          <p class="go-ahead"
             v-else>最高可赠送{{global.extra.subCoinData.maxGiveCoin}}奖金币哦，加油！</p>
          <p class="notice">每日0点赠送 自动发放到账号余额</p>
        </div>
        <div class="user-wrap">
          <p class="total"
             v-if="global.extra.cardGainCoin">累计获得奖励：{{global.extra.cardGainCoin || 0}}书币</p>
          <p class="balance">书币余额：{{global.extra.subCoinData.coin || 0}}书币</p>
        </div>
        <span class="click-area"
              @click="toActivity"></span>
      </div>
      <img src="./imgs/close_btn.png"
           alt=""
           class="close"
           @click="normalClose">
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog,
  },
  data() {
    return {};
  },
  computed: {
    today() {
      return (
        new Date().toLocaleDateString().slice(5).split("/").join("月") + "日"
      );
    },
    special() {
      return this.today !== "11月9日";
    },
  },
  methods: {
    toActivity() {
      let link = this.global.link;
      // if (this.is_recent) link = this.global.recent_link;
      location.href = link;
    },
    normalClose() {
      this.reject("close");
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-dialog__wrap {
  width: 85%;
}

.close {
  width: 0.6rem;
  height: 0.6rem;
  margin-top: 0.3rem;
}

.show-wrap-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  font-size: 0.28rem;
  color: #5f1512;
  width: 90%;

  .date {
    font-size: 0.48rem;
    font-weight: bold;
  }

  .text {
    font-size: 0.26rem;
    color: #af5450;

    span {
      display: block;

      &.coin {
        font-size: 0.28rem;
        color: #640f19;
        font-weight: bold;
      }
    }
  }

  .waiting-send {
    color: #cc3b27;
    background: rgba(255, 164, 123, 0.45);
    padding: 0.16rem 0;
    margin: 0.05rem auto;
    width: 60%;
    font-size: 0.28rem;
    line-height: 1;
    border-radius: 20px;
  }

  .go-ahead,
  .notice {
    font-size: 0.24rem;
    color: #640f19;
    font-weight: normal;
  }

  .notice {
    color: #e6a18f;
  }
}

.user-wrap {
  font-size: 0.24rem;
  color: #640f19;
  position: absolute;
  // bottom: 0.3rem;
  bottom: 1.5rem;
  left: 50%;
  // transform: translate(-50%, -280%);
  transform: translate(-50%, 0%);
}

.click-area {
  width: 80%;
  height: 0.9rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -45%);
}
</style>