<template>
  <van-action-sheet class="x-comment__red"
                    :round="false"
                    v-model="openComment"
                    @cancel="close">
    <div class="x-red__title"
         v-show="!tid">给作者个评论鼓励下吧~</div>
    <div class="x-red__form">
      <div class="x-red__form--input"
           v-show="!tid">
        <input type="text"
               class="x-input"
               placeholder="请输入标题"
               v-model="formComment.title">
        <span>选填</span>
      </div>
      <div class="x-red__form--input">
        <textarea name="content"
                  class="x-input"
                  rows="8"
                  placeholder="请输入内容(评论次数越多，或者评论字数多可以提升评论员等级)"
                  v-model="formComment.content"></textarea>
      </div>
    </div>
    <div class="x-push__box">
      <!-- <img src="../../assets/emoji.png"
           alt=""
           class="show-emoji"
           @click.stop="showEmoji = !showEmoji"> -->
      <div class="x-red__submit"
           @click.stop="pushComment">发表评论</div>
      <!-- <x-emoji @select.stop="addEmoji"
               v-show="showEmoji"></x-emoji> -->
    </div>
  </van-action-sheet>
</template>

<script>
import { publishComment } from "@/api";

export default {
  name: "XPushComment",
  props: {
    open: {
      type: Boolean,
      default() {
        return false;
      }
    },
    type: {
      // * 评论类型 addBookReview - 书评 addChapterReview - 章评 addReply - 回复
      type: String,
      default() {
        return "addBookReview";
      }
    },
    bid: {
      // * 书籍id
      type: [String, Number],
      required: true
    },
    cid: {
      // * 章节id
      type: [String, Number]
    },
    tid: {
      // * 评论id
      type: [String, Number]
    }
  },
  data() {
    return {
      formComment: {
        title: "",
        content: ""
      },
      commentLoading: false,
      showEmoji: false
    };
  },
  computed: {
    openComment: {
      // * 父子组件loading双向绑定
      get: function() {
        return this.open;
      },
      set: function(v) {
        this.$emit("update:open", v);
      }
    },
    successText() {
      return this.tid ? "感谢你的回复！" : "感谢你的评论！";
    },
    errorText() {
      return this.tid ? "您的评论内容好像没写哦~" : "您的回复内容好像没写哦~";
    }
  },
  watch: {
    openComment(v) {
      if (!v) this.close();
    }
  },
  methods: {
    pushComment() {
      if (this.formComment.content) {
        if (this.commentLoading) return;
        this.commentLoading = true;
        // * 发布评论
        this.formComment.action = this.type;
        this.formComment.bid = this.bid;
        if (this.tid) this.formComment.pid = this.tid;
        if (this.cid) this.formComment.chapterId = this.cid;
        publishComment(this.formComment)
          .then(r => {
            this.$toast(r.tip || this.successText);
            this.close();
            this.$emit("after-push");
          })
          .catch(e => {
            this.$toast(e.msg);
          });
      } else this.$toast(this.errorText);
    },
    addEmoji(name) {
      // * 添加表情
      this.showEmoji = false;
      this.formComment.contents += name;
    },
    reset() {
      this.formComment.title = "";
      this.formComment.content = "";
    },
    close() {
      this.openComment = false;
      this.commentLoading = false;
      this.reset();
      this.$emit("close");
    }
  },
  mounted() {
    window.addEventListener("popstate", () => {
      this.close();
    });
  }
};
</script>

