<template>
  <div class="skeleton-recent">
    <div class="x-recent__recommend hr-bottom">
        <s-book-multi></s-book-multi>
        <div class="x-recent__sign s-skeleton__rect--big"></div>
      </div>
    <div class="skeleton-part skeleton-part__box">
      <div class="s-skeleton__rect--big section-title"></div>
      <s-book-multi class="van-hairline--bottom" v-for="i in 3" :key="i" style="padding:.3rem 0"></s-book-multi>
    </div>
  </div>
</template>

<script>
export default {
  name: "XSkeletonRecent"
};
</script>

<style lang="scss" scoped>
@import "@/scss/components/skeleton/recent.scss";
</style>
