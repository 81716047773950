<template>
  <x-err-dialog :visible="visible">
    <div class="send-error-wrap">
      <template v-if="type === 'sendProps'">
        <div class="error-wrap error-gift-wrap">
          <p class="error-title">哎呀 您的余额不足</p>
        </div>
        <div class="error-operator-wrap">
          <p class="close"
             @click="close">算了 下次再送</p>
          <p class="sure"
             @click="toPay">前去充值<i v-if="isMainSite">老用户5折狂欢</i></p>
        </div>
      </template>
      <template v-else-if="type === 'sendTickets'">
        <div class="error-wrap error-month-wrap">
          <p class="error-title">哎呀 您本月的月票不足</p>
          <p class="error-tips">当月消费满<i>500</i>书币，即可获取<i>1</i>张月票，下月1日自动发放至账户，快去消费吧~</p>
        </div>
        <div class="error-operator-wrap">
          <p class="close"
             @click="close">我知道了</p>
          <p class="sure"
             @click="send">去打赏作者<i>送礼物可赠投月票</i></p>
        </div>
      </template>
      <template v-else-if="type === 'sendRecomms'">
        <div class="error-wrap error-ticket-wrap">
          <p class="error-title">哎呀 您今日的推荐票不足</p>
          <p class="error-tips">您当前等级<i>LV{{result.userLevel}}</i>，每日可获得<i>{{result.userDayTicket}}</i>张推荐票</p>
          <p class="error-tips">获取经验值努力升级就可以获得更多！</p>
        </div>
        <div class="error-operator-wrap error-operator-wrap-ticket">
          <p class="sure"
             @click="close">算了 明天再送</p>
        </div>
      </template>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";
import { Pay } from "@/views";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  data: () => ({
    isMainSite: !!window.ycsd.is_main_site
  }),
  methods: {
    send() {
      this.$toast({
        type: "loading",
        message: "请稍等...",
        forbidClick: true,
        duration: 0
      });
      this.resolve({ action: "continue", index: 0 });
      this.close();
    },
    toPay() {
      this.reject({ action: "pay" });
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.send-error-wrap {
  .error-wrap {
    padding: 0.4rem 0.24rem 0.3rem;

    .error-title {
      margin-top: 0.2rem;
      font-size: 0.32rem;
      color: #333;
      font-weight: bolder;
      margin-bottom: 0.19rem;
    }

    .error-tips {
      font-size: 0.24rem;
      color: #666;
      text-align: left;

      i {
        font-weight: bold;
        color: #ef5952;
      }
    }

    &.error-ticket-wrap {
      .error-tips {
        text-align: center;
      }
    }
  }

  .error-operator-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.3rem;
    padding: 0 0.24rem 0.24rem;

    p {
      flex: 1;
      border: 1px solid #ccc;
      border-radius: 0.12rem;
      font-size: 0.28rem;
      color: #999;
      height: 0.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin-right: 0.3rem;
      }

      &.sure {
        border-color: #ef5952;
        background: #ef5952;
        color: #fff;
        font-weight: bolder;
      }

      i {
        color: #ffe8e7;
        font-size: 0.22rem;
      }
    }

    &.error-operator-wrap-ticket {
      p {
        margin-right: 0;
      }
    }
  }
}
</style>
