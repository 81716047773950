/*
 * @Author: 晓晓晓晓晓丶vv
 * @Date: 2020-04-22 09:41:40
 * @LastEditors: 晓晓晓晓晓丶vv
 * @LastEditTime: 2020-05-07 16:32:49
 * @Description: app 交互
 * @FilePath: /src/utils/app.js
 */
/* eslint-disable */
import Vue from "vue";
const app = new Vue();

function isObject(data) {
  return toString.call(data) === "[object Object]";
}

function isArray(data) {
  return toString.call(data) === "[object Array]";
}

function transformAndroidData(data) {
  if (isObject(data) || isArray(data)) return JSON.stringify(data);
  return data;
}

const setupWebViewJavascriptBridge = (method, data, cb) => {
  // return new Promise((resolve) => {
  // });
  if (window.bridge) {
    window.bridge.callHandler(method, data, cb);
    return;
  }
  if (window.WebViewJavascriptBridge) {
    return WebViewJavascriptBridge;
  }
  if (window.WVJBCallbacks) {
    return window.WVJBCallbacks.push(cb);
  }
  window.WVJBCallbacks = [cb];
  var WVJBIframe = document.createElement("iframe");
  WVJBIframe.style.display = "none";
  WVJBIframe.src = "wvjbscheme://__BRIDGE_LOADED__";
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(function() {
    document.documentElement.removeChild(WVJBIframe);
  }, 0);
};

export const initAppClient = ({
  ios_method,
  android_method,
  params,
  is_return = false,
}) => {
  // const ios_app = await setupWebViewJavascriptBridge();
  const { ycsd_app } = window;
  const isApp = !!window.ycsd.is_app;
  let returnVal = null;
  if (isApp) {
    if (ycsd_app) {
      returnVal = params
        ? ycsd_app[android_method](transformAndroidData(params))
        : ycsd_app[android_method]();
    } else {
      setupWebViewJavascriptBridge(ios_method, params, (bridge) => {
        if (!window.bridge) window.bridge = bridge;
        window.bridge.callHandler(ios_method, params, (res) => {
          console.log("response from ios:", res);
          returnVal = res;
        });
      });
    }
    if (is_return) return returnVal;
  }
};

export const appLog = (message, data) => {
  const isApp = !!window.ycsd.is_app;
  if (isApp) {
    var el = document.createElement("div");
    var html = `<p>${message}</p>`;
    if (data) html += `<p>${JSON.stringify(data)}</p>`;
    el.innerHTML = html;
    document.getElementById("app").appendChild(el);
  }
};

window.emit = function emit(method, params) {
  app.$emit(method, params);
  return { method, params };
};

export { app };
