/* eslint-disable */
function refreshRem() {
  document.documentElement.style.fontSize =
    (Math.min(window.innerWidth, screen.width, document.body.offsetWidth) /
      750) *
      100 +
    "px";
}
window.addEventListener(
  "resize",
  function() {
    clearTimeout(tid);
    tid = setTimeout(refreshRem, 300);
  },
  false
);
window.addEventListener(
  "pageshow",
  function(e) {
    if (e.persisted) {
      clearTimeout(tid);
      tid = setTimeout(refreshRem, 300);
    }
  },
  false
);
var tid;
refreshRem();

if (
  typeof WeixinJSBridge == "object" &&
  typeof WeixinJSBridge.invoke == "function"
) {
  handleFontSize();
} else {
  if (document.addEventListener) {
    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
  } else if (document.attachEvent) {
    document.attachEvent("WeixinJSBridgeReady", handleFontSize);
    document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
  }
}

function handleFontSize() {
  // 设置网页字体为默认大小
  WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
  // 重写设置网页字体大小的事件
  WeixinJSBridge.on("menu:setfont", function() {
    WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
  });
}
