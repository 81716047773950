<template>
  <x-err-dialog class="crm-activity-wrap"
                :visible="visible">
    <div class="bg-wrap">
      <img :src="img"
           alt="">
      <span class="close-area"
            @click="close"></span>
    </div>
    <img src="./imgs/close_btn.png"
         alt=""
         class="close-btn"
         @click="normalClose">
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog,
  },
  data: () => ({}),
  methods: {
    normalClose() {
      this.reject("close");
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-activity-wrap {
  border-radius: 0;
  font-size: 0;
  background: transparent;

  .bg-wrap {
    font-size: 0;
    position: relative;

    img {
      width: 100%;
      border-radius: 16px;
    }

    .close-area {
      position: absolute;
      bottom: 13%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 90%;
      height: 1rem;
    }
  }

  .close-btn {
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 0.4rem;
  }
}
</style>