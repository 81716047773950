<template>
  <div class="skeleton-gift">
    <div class="x-gift__rank">
      <div class="x-detail__part--gift">
        <div class="gift-item gift-item__one">
          <p class="s-skeleton__circle"></p>
          <p class="gift-item__rank s-skeleton__rect--small"></p>
          <p class="gift-item__name  s-skeleton__rect--small"></p>
          <p class="gift-item__coins  s-skeleton__rect--small"></p>
        </div>
        <div class="gift-item gift-item__two">
          <p class="s-skeleton__circle"></p>
          <p class="gift-item__rank s-skeleton__rect--small"></p>
          <p class="gift-item__name s-skeleton__rect--small"></p>
          <p class="gift-item__coins s-skeleton__rect--small"></p>
        </div>
        <div class="gift-item gift-item__three">
          <p class="s-skeleton__circle"></p>
          <p class="gift-item__rank s-skeleton__rect--small"></p>
          <p class="gift-item__name s-skeleton__rect--small"></p>
          <p class="gift-item__coins s-skeleton__rect--small"></p>
        </div>
      </div>
    </div>
    <div class="skeleton-part skeleton-part__box">
      <div class="s-skeleton__rect--big section-title"></div>
      <div class="s-skeleton__rect--biger"
           v-for="i in 4"
           :key="i"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "XSkeletonGift"
};
</script>

<style lang="scss" scoped>
@import "@/scss/components/skeleton/gift.scss";
</style>


