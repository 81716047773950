<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <template v-if="isApp">
      <van-overlay class="guide-overlay"
                   :show="show_guide"
                   z-index="9999">
        <div class="guide-step"
             @click="guideStep">
          <img src="@/assets/step.png"
               alt="">
        </div>
        <div class="guide-wrap guide-home"
             v-if="show_guide_home">
          <img class="guide-title"
               src="@/assets/guide-home-title.png"
               alt="">
          <div class="guide-main">
            <img src="@/assets/guide-home.png"
                 alt=""
                 @click="guideRecent">
          </div>
        </div>
        <div class="guide-wrap guide-recent"
             v-else-if="show_guide_recent">
          <img src="@/assets/guide-recent.png"
               alt=""
               @click="guideBook">
        </div>
      </van-overlay>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import {
  Activity,
  FiveOneActivity,
  EasyActivity,
  CrmActivity,
  RedPacketInGlobal,
  MonthlyNotify,
} from "@/components/dialog";

import { recharge } from "@/utils";

import { weekCards } from "@/api";
import { mapState, mapActions } from "vuex";
import { app } from "./utils/app";
import { isOnePageBack } from "./utils/app_register";

export default {
  data: () => ({
    show_guide: false,
    show_guide_home: false,
    show_guide_recent: false,
    guide_array: JSON.parse(localStorage.getItem("guide") || "[]"),
  }),
  computed: {
    ...mapState("system", ["routerStack", "isApp"]),
  },
  mounted() {
    this.weekCards = window.ycsd.activity_position.global;
    if (this.weekCards.length) {
      const isActivity = this.findTargetActivity("activity");
      const isWeekCard = this.findTargetActivity("week-card");
      const isCrmActivity = this.findTargetActivity("crm");
      if (isActivity) this.showSimpleActivity(isActivity);
      if (isWeekCard) this.showActivity();
      if (isCrmActivity) this.showCrmActivity(isCrmActivity);
    }
    // this.shengdan = window.ycsd.activity.global;
    // if (this.shengdan.link) this.showEasyActivity();
    // app端路由栈控制
    if (this.isApp) {
      this.appTriggerBack();
      setTimeout(() => {
        this.$nextTick(() => {
          this.isFirstGuide();
        });
      }, 1000);
    }
  },
  watch: {
    $route(to, from) {
      if (to.name !== "reader") {
        const isRedPacket = this.findTargetActivity("red_packet");
        isRedPacket && this.showRedPacketNotice(isRedPacket.link);
        // 判断是否弹过包月
        // NOTE: 没做阅读器内刷新的情况处理
        const index = this.findTargetActivityIndex("bag");
        if (this.weekCards.length === 1 && !!~index) this.showMonthlyNotify();
      }
      if (to.path === "/stock/recent") this.isFirstGuide();
    },
  },
  methods: {
    showActivity() {
      const index = this.findTargetActivityIndex("activity");
      weekCards()
        .then((r) => {
          this.activityData = r;
          return Activity({ r });
        })
        .then(() => {
          recharge({
            product_id: this.activityData.product_id,
            is_activity: true,
            activity_id: this.activityData.id,
            send_pos: this.weekCards[0].position,
          });
        })
        .catch((e) => {
          console.log("week card error", e);
          !!~index && this.removeGlobalActivityByIndex(index);
          setTimeout(() => {
            this.showMonthlyNotify();
          }, 650);
        });
    },
    showEasyActivity(info) {
      EasyActivity({ info }).catch((e) => {
        console.log("already opened");
      });
    },
    showSimpleActivity({ link }) {
      const index = this.findTargetActivityIndex("activity");
      Activity()
        .then((_) => {
          location.href = link;
        })
        .catch((e) => {
          console.log(e);
          !!~index && this.removeGlobalActivityByIndex(index);
          setTimeout(() => {
            this.showMonthlyNotify();
          }, 650);
        });
    },
    showFiveOneActivity(info) {
      const index = this.findTargetActivityIndex("activity");
      FiveOneActivity({ global: info }).catch((e) => {
        console.log(e);
        !!~index && this.removeGlobalActivityByIndex(index);
        setTimeout(() => {
          this.showMonthlyNotify();
        }, 650);
      });
    },
    showCrmActivity(info) {
      const index = this.findTargetActivityIndex("crm");
      const data = info.extra;
      CrmActivity({ img: info.img })
        .then((_) => {
          location.href = data.link;
        })
        .catch((e) => {
          console.log("close");
          !!~index && this.removeGlobalActivityByIndex(index);
          setTimeout(() => {
            this.showMonthlyNotify();
          }, 650);
        });
    },
    showRedPacketNotice(targetRouter) {
      RedPacketInGlobal()
        .then((_) => {
          location.href = targetRouter;
          // this.$router.push(targetRouter);
        })
        .catch((e) => console.log(e));
    },
    findTargetActivity(target) {
      const result = window.ycsd.activity_position.global.filter(
        (w) => w.type === target
      );
      return result[0];
    },
    findTargetActivityIndex(target) {
      const index = window.ycsd.activity_position.global.findIndex(
        (w) => w.type === target
      );
      return index;
    },
    removeGlobalActivityByIndex(index) {
      window.ycsd.activity_position.global.splice(index, 1);
    },
    showMonthlyNotify() {
      const monthly = this.findTargetActivity("bag");
      const monthlyIndex = this.findTargetActivityIndex("bag");
      const is_in_reader = !!~location.href.indexOf("/reader/");
      if (monthly && !is_in_reader) {
        !!~monthlyIndex && this.removeGlobalActivityByIndex(monthlyIndex);
        MonthlyNotify({
          month: monthly.extra.bagAlert,
          link: monthly.link,
        }).catch((e) => {
          console.log(e);
        });
      }
    },
    appTriggerBack() {
      app.$on("back", async () => {
        if (this.routerStack.length > 1) {
          // 移除栈的最后一个
          await this.removeRouterStack();
          this.$router.back();
          // 兼容app内back无效 - 不算完美的方法
          this.replaceLocation();
        } else isOnePageBack();
      });
    },
    replaceLocation() {
      try {
        setTimeout(() => {
          let stackLen = this.routerStack.length;
          let path = this.routerStack[stackLen - 1];
          // const { pathname, search } = window.location;
          // let path = pathname + search;
          this.$router.replace(path[1]);
        }, 1);
        return false;
      } catch (e) {}
    },
    guideStep() {
      this.guide_array.push(this.$route.path);
      localStorage.setItem("guide", JSON.stringify(this.guide_array));
      this.show_guide = false;
    },
    isFirstGuide() {
      const path = this.$route.path;
      const guide = this.guide_array;
      this.show_guide_home = !guide.includes("/") && path === "/";
      this.show_guide_recent =
        !guide.includes("/stock/recent") && path === "/stock/recent";
      this.show_guide = this.show_guide_home || this.show_guide_recent;
    },
    guideRecent() {
      this.show_guide_home = false;
      this.guide_array.push("/");
      localStorage.setItem("guide", JSON.stringify(this.guide_array));
      this.$router.push("/stock/recent");
    },
    guideBook() {
      // TODO 待优化
      const page = this.$children[0].$children[0];
      const recent = page.$children[page.$children.length - 1];
      if (recent.list[0]) {
        this.show_guide_recent = false;
        this.guideStep();
        location.href = `/detail/${recent.list[0].bid}`;
      } else {
        this.show_guide_recent = false;
        this.guideStep();
      }
    },
    ...mapActions("system", ["removeRouterStack"]),
  },
};
</script>

<style lang="scss">
.guide-overlay {
  img {
    width: 100%;
  }

  .guide-wrap {
    position: relative;
    text-align: center;
    font-size: 0;
  }

  .guide-title {
    width: 5rem;
    margin-top: 1.2rem;
  }

  .guide-step {
    position: absolute;
    right: 0.4rem;
    top: 0.4rem;
    font-size: 0;
    z-index: 999;

    img {
      width: 1.1rem;
    }
  }

  .guide-home {
    .guide-main {
      text-align: left;
      position: relative;
      font-size: 0;
      margin-top: 0.7rem;

      img {
        width: 50%;
      }
    }
  }

  .guide-recent {
    text-align: center;
    margin-top: 6.8rem;

    img {
      width: 95%;
    }
  }
}
</style>