<template>
  <div class="skeleton-monthly">
    <div class="x-monthly__part hr-bottom">
      <p class="s-skeleton__rect--big section-title"></p>
      <div class="x-monthly__list">
        <div class="x-monthly__list--item s-skeleton__rect--small" v-for="i in 8" :key="i"></div>
      </div>
      <p class="s-skeleton__rect--small s-short"></p>
      <p class="s-skeleton__rect--small s-shorter"></p>
      <p class="s-skeleton__rect--big s-btn"></p>
    </div>
    <div class="x-monthly__part">
      <div class="x-monthly__books">
        <s-book-multi v-for="i in 3" :key="i"></s-book-multi>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "XSkeletonMonthly"
};
</script>

<style lang="scss" scoped>
@import '@/scss/components/skeleton/monthly.scss';
</style>