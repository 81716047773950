<template>
  <div class="skeleton-home-page">
    <div class="x-city__header van-hairline--bottom"
         v-if="!isApp">
      <img src="../../assets/logo.png"
           alt=""
           class="x-city__logo">
      <div class="x-city__search">
        <input class="x-city__search--input"
               type="text"
               placeholder="请输入搜索关键字">
      </div>
    </div>
    <div class="skeleton-home">
      <section class="skeleton-part">
        <div class="s-skeleton__rect"></div>
        <div class="s-skeleton__flex">
          <div class="s-skeleton__item"
               v-for="i in 5"
               :key="i">
            <div class="s-skeleton__circle"></div>
            <div class="s-skeleton__rect--small"></div>
          </div>
        </div>
      </section>
      <section class="skeleton-part hr-top">
        <div class="s-skeleton__rect--big section-title"></div>
        <div class="s-skeleton__flex">
          <div class="s-skeleton__item section-book"
               v-for="i in 4"
               :key="i">
            <s-book></s-book>
          </div>
        </div>
        <div class="skeleton-box">
          <div class="skeleton-item"
               v-for="i in 3"
               :key="i">
            <s-book-no-cover></s-book-no-cover>
          </div>
        </div>
      </section>
      <section class="skeleton-part hr-top">
        <div class="s-skeleton__rect--big section-title"></div>
        <div class="skeleton-box">
          <div class="skeleton-item"
               v-for="i in 5"
               :key="i">
            <s-book-no-cover></s-book-no-cover>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "XSkeletonHome",
  computed: {
    ...mapState("system", ["isApp"])
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/components/skeleton/home.scss";
</style>
