<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-01-09 14:06:31
 * @LastEditors: ZhengXiaowei
 * @LastEditTime: 2019-11-29 15:46:41
 * @Description: file content
 -->
<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask>
    <div class="chapter-pay-dialog">
      <p class="dialog-title van-hairline--bottom">VIP章节提醒<span class="close"
              @click.stop="visible=false"></span></p>
      <div class="dialog-content">
        <div class="chapter-info">
          <!-- <p>《{{bookName}}》</p> -->
          <p class="strong">{{chapterTitle}}<img class="is_vip"
                 src="../../../assets/is_vip.png" /></p>
        </div>
        <div class="chapter-price__new">
          <p>余额：<i>{{originCoin}}</i>原创币+<i>{{awardCoin}}</i>奖金币</p>
          <!-- <p>本章价格：<i>{{price}}</i>书币</p> -->
          <p class="current-chapter__price">本章价格：<i>{{price}}</i>书币 <span v-if="isMainSite">原价：{{vipInfo.originPrice}}书币</span></p>
        </div>
        <img :src="vipCover"
             alt=""
             class="vip-cover">
      </div>
      <!-- <div class="chapter-notice">购买本章可以获得<span>{{exp}}</span>经验值<img src="../../../assets/question.png"
             @click.stop="toAccount" /></div> -->
      <div class="dialog-footer">
        <p class="dialog-confirm-btn dialog-confirm-btn__new"
           @click="close">购买本章 <span>{{showTipsByUserLevel()}}</span></p>
      </div>
      <div class="chapter-notice">购买本章可以获得<span>{{exp}}</span>经验值<img src="../../../assets/question.png"
             @click.stop="toAccount" /></div>
      <!-- <div class="dialog-tips">
        <div class="tips-box">
          <span><i></i>已购买章节不会重复扣费</span>
          <span><i></i>后续VIP章节订购不再提醒</span>
          <span><i></i>资费说明：普通会员千字9分；初级VIP会员千字7分；高级VIP会员千字5分。</span>
        </div>
      </div> -->
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

import { MAIN_VIP_TARGET_MAP, VIP_TARGET_MAP } from "./options/vip-mark";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  data: () => ({
    main_vip: MAIN_VIP_TARGET_MAP,
    normal_vip: VIP_TARGET_MAP,
    isMainSite: !!window.ycsd.is_main_site
  }),
  computed: {
    exp() {
      return Math.ceil((this.price / 100) * 20);
    },
    vipCover() {
      let { userVipLevel } = this.vipInfo;
      return this.isMainSite
        ? this.main_vip[userVipLevel].cover
        : this.normal_vip[userVipLevel].cover;
    }
  },
  methods: {
    toAccount() {
      this.visible = false;
      this.reject("account");
    },
    showTipsByUserLevel() {
      let {
        isNeedUpgrade,
        userVipLevel,
        userNextVipLevel,
        nextCoin
      } = this.vipInfo;
      if (!this.isMainSite) return "";
      if (!isNeedUpgrade) return "";
      else {
        return (
          "升级为" +
          this.main_vip[userNextVipLevel].title +
          "会员本章只消耗" +
          nextCoin +
          "书币"
        );
      }
    }
  }
};
</script>
