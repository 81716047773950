<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask>
    <div class="week-sign__wrap">
      <span class="close"
            @click.stop="visible=false"></span>
      <template v-if="!isActive">
        <img src="../../../assets/un-active-bg.png"
             alt="未激活">
        <a class="week-sign__active" href="/api/pay/weekCard.aspx"></a>
      </template>
      <template v-else>
        <img src="../../../assets/week_sign/bg.png"
             alt="激活周卡">
        <div class="top-text__wrap">
          <p class="strong-title">尊贵的周卡用户：</p>
          <p class="awards">{{weeksObj.config.send}}奖金币已发放至账户，请查收~</p>
          <p class="total-awards">您已累计领取：{{weeksObj.total}}奖金币</p>
          <p class="week-validity">周卡有效期：{{weeksObj.config.startTime.substr(0, 10)}}至{{weeksObj.config.endTime.substr(0, 10)}}</p>
        </div>
        <div class="content-cards__wrap">
          <template v-if="weeksObj.config.limit">
            <p class="expire-time">还有<i>{{weeksObj.config.limit}}</i>天过期</p>
          </template>
          <template v-else>
            <p class="expire-time">周卡已过期</p>
          </template>
          <div class="week-cards">
            <template v-for="(week, i) in weekArr">
              <div class="week-card"
                   :key="i">
                <img :src="(i + 1) <= weeksObj.days ? weekArr[i][1] : weekArr[i][0]"
                     alt="">
                <span v-if="(i + 1) <= weeksObj.days">已领取</span>
              </div>
            </template>
          </div>
          <div class="week-rules">
            <p class="rule-item">1.周卡有效期<i>7</i>天，记得每天来看书领奖金币哦；</p>
            <p class="rule-item">2.每日登录自动发放，请去个人中心查收；</p>
          </div>
        </div>
      </template>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

import WeekArr from "./options/week.js";
import weekArr from "./options/week.js";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  data() {
    return {
      weekArr: weekArr
    };
  }
};
</script>
