<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-11-13 09:28:41
 * @LastEditors: ZhengXiaowei
 * @LastEditTime: 2019-11-14 10:01:32
 * @Description: 书卷列表
 -->
<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask>
    <div class="book-catalog-type-wrap">
      <template v-for="item in list">
        <p class="catalog-type-item"
           :key="item.id"
           @click="selectTitle(item)">{{item.volumeTitle}}</p>
      </template>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  methods: {
    selectTitle(item) {
      this.resolve({ volume: item });
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.book-catalog-type-wrap {
  max-height: 1.1rem * 4;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .catalog-type-item {
    font-size: 0.26rem;
    color: #666;
    line-height: 1.08rem;

    &:not(:last-child) {
      border-bottom: 1px solid #f7f7f7;
    }
  }
}
</style>