<template>
  <div class="x-page__wrapper">
    <!-- 页面头部 -->
    <div class="x-page__header van-hairline--bottom"
         v-show="!hideHeader && !isApp">
      <a class="back"
         href="javascript:history.back();">
        <!-- <img class="x-page__icon"
             src="../../assets/back.png"
             alt=""> -->
      </a>
      <!-- 头部可自定义替换 -->
      <slot name="header">
        <span class="x-page__title">{{title && title!='undefined' ? title : $route.meta.Chinese}}</span>
      </slot>
      <a class="header-menu"
         href="/">
        <i class="icon-shouye-h"
           style="font-size: .4rem"></i>
        <!-- <router-link tag="i"
                    to="/"
                    replace
                    class="icon-shouye-h"
                    style="font-size:.4rem">
        </router-link> -->
      </a>
    </div>
    <!-- 问题太多 -->
    <!-- 使用tranform 会让fixed属性失效 -->
    <!-- 目前在书评、粉丝列表、最近阅读、书架有用到fixed -->
    <!-- <van-pull-refresh :class="['x-page__content', hideHeader ? 'x-page__content--noheader' : '']"
                      v-model="isLoading"
                      :disabled="!$route.meta.refresh"
                      pulling-text="下拉即可刷新"
                      loosing-text="释放即可刷新"
                      loading-text="别着急哟，正在努力加载ing..."
                      @refresh="refresh">
    </van-pull-refresh> -->
    <!-- 页面主体内容 -->
    <div class="x-page__content">
      <!-- 默认使用骨架屏 -->
      <slot>
        <template v-if="skeletonComponent">
          <component :class="['skeleton-component', {'no-header' : hideHeader}]"
                     :is="skeletonComponent"></component>
        </template>
      </slot>
      <!-- <transition name="van-fade">
      </transition> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "XPage",
  props: {
    hideHeader: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showMenu: false
    };
  },
  created() {
    
  },
  watch: {
    showMenu(v) {
      let docStyle = document.body.style;
      if (v) docStyle.overflow = "hidden";
      else docStyle.overflow = "";
    }
  },
  computed: {
    skeletonComponent() {
      // * 默认加载骨架屏
      let meta = this.$route.meta;
      if (meta.Skeleton)
        return `x-skeleton-${this.$route.meta.Skeleton.toLowerCase()}`;
      else return "";
    },
    isLoading: {
      // * 父子组件loading双向绑定
      get: function() {
        return this.loading;
      },
      set: function(v) {
        this.$emit("update:loading", v);
      }
    },
    ...mapState("system", ["isApp"])
  },
  methods: {
    refresh() {
      // * 刷新事件
      this.$emit("refresh");
    }
  }
};
</script>
