<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask>
    <div class="chapter-pay-dialog">
      <p class="dialog-title van-hairline--bottom">全本购买<span class="close"
              @click.stop="visible=false"></span></p>
      <div class="dialog-content">
        <div class="chapter-info">
          <p>《{{bookName}}》</p>
        </div>
        <div class="chapter-price">
          <p>本书价格：<i>{{price}}</i>书币</p>
          <p>余额：<i>{{originCoin}}</i>书币 <i>{{awardCoin}}</i>奖金币</p>
        </div>
      </div>
      <div class="chapter-notice">购买本章可以获得<span>{{exp}}</span>经验值<img src="../../../assets/question.png"  @click.stop="toAccount"/></div>
      <div class="dialog-footer">
        <p class="dialog-confirm-btn"
           @click="close">购买本书</p>
      </div>
      <div class="dialog-tips">
        <div class="tips-box">
          <span><i></i>已购买章节不会重复扣费</span>
          <span><i></i>后续VIP章节订购不再提醒</span>
          <span><i></i>资费说明：普通会员千字9分；初级VIP会员千字7分；高级VIP会员千字5分。</span>
        </div>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  computed: {
    exp() {
      return Math.ceil(this.price / 100) * 20;
    }
  },
  methods: {
    toAccount() {
      this.visible = false;
      this.reject("account");
    }
  }
};
</script>
