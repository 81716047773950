<template>
  <div class="skeleton-library">
    <div class="x-library__category van-hairline--bottom">
      <div class="x-category__box"
           v-for="pI in 3"
           :key="pI">
        <div class="x-category__box--title lib-title nmr">
          <div class="s-skeleton__rect--small"></div>
        </div>
        <div class="x-category__box--list">
          <div :class="['s-skeleton__rect--small lib-title',i % 3 === 0 ? 'nmr': '']"
               v-for="i in pI === 1 ? 10 : 3"
               :key="i"></div>
        </div>
      </div>
    </div>
    <div class="x-library__category--more">
      <div class="s-skeleton__rect--small lib-more"></div>
    </div>
    <div class="x-library__books">
      <s-book-multi v-for="i in 4" :key="i" style="margin-bottom:0.2rem"></s-book-multi>
    </div>
  </div>
</template>

<script>
export default {
  name: "XSkeletonLibrary"
};
</script>

<style lang="scss" scoped>
@import '@/scss/components/skeleton/library.scss';
</style>
