<template>
  <div class="skeleton-detail">
    <s-book-multi></s-book-multi>
    <div class="skeleton-part__box skeleton-menu">
      <div class="s-skeleton__rect" v-for="i in 3" :key="i"></div>
    </div>
    <div class="skeleton-part__box skeleton-intro">
      <div class="s-skeleton__rect--small" v-for="i in 3" :key="i"></div>
    </div>
    <div class="skeleton-part skeleton-part__box" v-for="i in 3" :key="i">
      <div class="s-skeleton__rect--big section-title"></div>
      <div class="s-skeleton__rect--small" v-for="i in 4" :key="i"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XSkeletonDetail'
}
</script>

<style lang="scss" scoped>
@import '@/scss/components/skeleton/detail.scss';
</style>
