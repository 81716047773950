/*
 * @Author: ZhengXiaowei
 * @Date: 2019-01-16 14:36:55
 * LastEditors: 晓晓晓晓晓丶vv
 * LastEditTime: 2020-05-25 15:55:54
 * @Description: file content
 */
// * meta参数解释
/**
 * Chinese: 页面标题
 * Skeleton: 所需要的骨架 不需要则为空 或不填写该字段 主要用于加载数据前展示
 * keepAlive: 该页面组件是否需要使用keep-alive进行缓存
 * auth: 该页面组件是否需要登录权限才能访问 不需要可不填
 * refresh: 该页面可以进行下拉刷新 可不填写 * TODO: 问题太多 暂时关闭该属性配置
 */

export const Home = {
  name: "Home",
  path: "/",
  meta: {
    Chinese: "首页",
    Skeleton: "home",
    keepAlive: false,
  },
  component: () => import("./Home.vue"),
};

export const Library = {
  name: "Library",
  path: "/library",
  meta: {
    Chinese: "书库",
    Skeleton: "library",
    keepAlive: true,
  },
  component: () => import("./Library.vue"),
};

export const SetPhone = {
  name: "setphone",
  path: "/setphone",
  meta: {
    Chinese: "绑定手机号",
    
    keepAlive: true,
  },
  component: () => import("./SetPhone.vue"),
};

export const SetPwd = {
  name: "SetPwd",
  path: "/setpwd",
  meta: {
    Chinese: "设置密码",
   
    keepAlive: true,
  },
  component: () => import("./SetPwd.vue"),
};



export const BookRank = {
  name: "BookRank",
  path: "/rank",
  meta: {
    Chinese: "排行榜",
    Skeleton: "rank",
    keepAlive: true,
  },
  component: () => import("./BookRank.vue"),
};

export const PersonalCenter = {
  name: "PersonalCenter",
  path: "/personalcenter",
  meta: {
    Chinese: "个人中心",
    auth: true,
    Skeleton: "personalcenter",
    keepAlive: false,
  },
  component: () => import("./PersonalCenter.vue"),
};

export const Detail = {
  name: "detail",
  path: "/detail/:bid",
  meta: {
    Chinese: "书籍详情",
    auth: true,
    Skeleton: "detail",
    keepAlive: false,
  },
  component: () => import("./BookDetail.vue"),
};

export const RechargeRecord = {
  name: "RechargeRecord",
  path: "/rechargerecord",
  meta: {
    Chinese: "充值记录",
    auth: true,
    Skeleton: "rechargerecord",
    keepAlive: false,
  },
  component: () => import("./RechargeRecord.vue"),
};

export const Account = {
  name: "Account",
  path: "/account",
  meta: {
    Chinese: "账户",
    auth: true,
    Skeleton: "account",
    keepAlive: false,
  },
  component: () => import("./Account.vue"),
};

export const RewardRecord = {
  name: "RewardRecord",
  path: "/rewardrecord",
  meta: {
    Chinese: "送礼记录",
    auth: true,
    Skeleton: "rewardrecord",
    keepAlive: false,
  },
  component: () => import("./RewardRecord.vue"),
};

export const BookCatalog = {
  name: "catalog",
  path: "/catalog/:bid",
  meta: {
    Chinese: "目录",
    Skeleton: "catalog",
    keepAlive: false,
  },
  component: () => import("./BookCatalog.vue"),
};

export const BookReader = {
  name: "reader",
  path: "/reader/:bid/:chapter_id",
  meta: {
    Chinese: "阅读器",
    auth: true,
    Skeleton: "reader",
    keepAlive: false,
  },
  component: () => import("./Reader.vue"),
};

// 阅读器进入的书评 含章节评论
// type: book -> 详情页进入 reader -> 阅读器进入
export const Comment = {
  name: "comment",
  path: "/comment/:type",
  meta: {
    Chinese: "书评",
    Skeleton: "comment",
    keepAlive: false,
  },
  component: () => import("./Comment.vue"),
};

export const CommentDetail = {
  name: "comment-detail",
  path: "/comment_detail",
  meta: {
    Chinese: "书评详情",
    Skeleton: "comment-detail",
    keepAlive: false,
  },
  component: () => import("./CommentDetail.vue"),
};

export const Pay = {
  name: "pay",
  path: "/recharge",
  meta: {
    Chinese: "充值",
    auth: true,
    Skeleton: "pay",
    keepAlive: false,
  },
  component: () => import("./Pay.vue"),
};

export const PayForOld = {
  name: "payForOld",
  path: "/pay_old",
  meta: {
    Chinese: "充值",
    auth: true,
    Skeleton: "pay",
    keepAlive: false,
  },
  component: () => import("./PayForOld.vue"),
};

export const PayForSpecialTemplate = {
  name: "PayForSpecialTemplate",
  path: "/pay_special_template",
  meta: {
    Chinese: "充值",
    auth: true,
    Skeleton: "pay",
    keepAlive: false,
  },
  component: () => import("./PayForZSTemplate.vue"),
};

export const PayForNormalTemplate = {
  name: "PayForNormalTemplate",
  path: "/pay_normal_template",
  meta: {
    Chinese: "充值",
    auth: true,
    Skeleton: "pay",
    keepAlive: false,
  },
  component: () => import("./PayForSJTemplate.vue"),
};

export const GiftRank = {
  name: "gift",
  path: "/gift/:bid",
  meta: {
    Chinese: "礼物排行榜",
    Skeleton: "gift",
    keepAlive: false,
  },
  component: () => import("./GiftRank.vue"),
};

export const FansRank = {
  name: "fans",
  path: "/fans/:bid",
  meta: {
    Chinese: "粉丝排行榜",
    Skeleton: "fans",
    keepAlive: false,
  },
  component: () => import("./FansRank.vue"),
};

// 书籍评论
// export const BookComment = {
//   name: "book-comment",
//   path: "/book",
//   component: () => import("./BookComment.vue")
// };

export const SubscriptionRecord = {
  name: "SubscriptionRecord",
  path: "/subscriptionrecord",
  meta: {
    Chinese: "订阅记录",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./SubscriptionRecord.vue"),
};

export const Reward = {
  name: "Reward",
  path: "/reward",
  meta: {
    Chinese: "奖金币明细",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./Reward.vue"),
};

export const mobileManger = {
  name: "Mobile",
  path: "/mobile",
  meta: {
    Chinese: "账号管理",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./Mobile.vue"),
};


export const ExpiredRecord = {
  name: "ExpiredRecord",
  path: "/expiredrecord",
  meta: {
    Chinese: "过期记录",
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./ExpiredRecord.vue"),
};

export const MyComment = {
  name: "MyComment",
  path: "/mycomment",
  meta: {
    Chinese: "我的评论",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./MyComment.vue"),
};

export const MyCommentDetail = {
  name: "MyCommentDetail",
  path: "/mycomment_detail",
  meta: {
    Chinese: "我的评论",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./MyCommentDetail.vue"),
};

export const AuthorCenter = {
  name: "AuthorCenter",
  path: "/author/:id",
  meta: {
    Chinese: "作者中心",
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./AuthorCenter.vue"),
};

export const ReaderCenter = {
  name: "ReaderCenter",
  path: "/readers/:id",
  meta: {
    Chinese: "读者中心",
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./ReaderCenter.vue"),
};

export const Stock = {
  name: "Stock",
  path: "/stock",
  meta: {
    Chinese: "用户书库",
    Skeleton: "",
    keepAlive: false,
  },
  children: [
    {
      name: "recent",
      path: "recent",
      meta: {
        Chinese: "最近阅读",
        auth: true,
        Skeleton: "",
      },
      component: () => import("./Recent.vue"),
    },
    {
      name: "shelf",
      path: "shelf",
      meta: {
        Chinese: "我的书架",
        auth: true,
        Skeleton: "",
      },
      component: () => import("./Shelf.vue"),
    },
    {
      name: "background",
      path: "red-background",
      meta: {
        Chinese: "红包广场",
        auth: true,
        Skeleton: "",
      },
      component: () => import("./RedBackground.vue"),
    },
  ],
  component: () => import("./Stock.vue"),
  redirect: "/stock/recent",
};
export const FanLevel = {
  name: "FanLevel",
  path: "/fanlevel",
  meta: {
    auth: true,
    Chinese: "粉丝等级",
    Skeleton: "fanlevel",
    keepAlive: false,
  },
  component: () => import("./FanLevel.vue"),
};

export const Notification = {
  name: "Notification",
  path: "/notification",
  meta: {
    Chinese: "消息通知",
    auth: true,
    Skeleton: "notification",
    keepAlive: false,
  },
  component: () => import("./Notification.vue"),
};

export const MyTicket = {
  name: "MyTicket",
  path: "/myticket/:VipLevel/:UserLevel",
  meta: {
    Chinese: "我的票券",
    auth: true,
    Skeleton: "myticket",
    keepAlive: false,
  },
  component: () => import("./MyTicket.vue"),
};

export const AuthorSay = {
  name: "AuthorSay",
  path: "/authorsay",
  meta: {
    Chinese: "作者有话说",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./AuthorSay.vue"),
};

export const SearchCenter = {
  name: "SearchCenter",
  path: "/searchcenter",
  meta: {
    Chinese: "搜索中心",
    auth: true,
    Skeleton: "",
    keepAlive: true,
  },
  component: () => import("./SearchCenter.vue"),
};

export const HisComment = {
  name: "HisComment",
  path: "/hiscomment/:id",
  meta: {
    Chinese: "Ta的评论",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./HisComment.vue"),
};

export const HisInteraction = {
  name: "HisInteraction",
  path: "/hisinteraction/:id",
  meta: {
    Chinese: "Ta的互动",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./HisInteraction.vue"),
};

export const CommentatorLevel = {
  name: "CommentatorLevel",
  path: "/commentatorlevel",
  meta: {
    Chinese: "评论员详情",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./CommentatorLevel.vue"),
};

export const lastPage = {
  name: "lastPage",
  path: "/lastPage/:bid",
  meta: {
    Chinese: "尾页推荐",
    auth: true,
    Skeleton: "",
    keepAlive: false,
  },
  component: () => import("./lastPage.vue"),
};

export const AccountInfo = {
  name: "AccountInfo",
  path: "/accountinfo",
  meta: {
    Chinese: "等级",
    Skeleton: "",
    keepAlive: false,
  },
  children: [
    {
      name: "viplevel",
      path: "viplevel",
      meta: {
        Chinese: "VIP等级",
        Skeleton: "",
      },
      component: () => import("./VipLevel.vue"),
    },
    {
      name: "accountlevel",
      path: "accountlevel",
      meta: {
        Chinese: "账号等级",
        auth: true,
        Skeleton: "",
      },
      component: () => import("./AccountLevel.vue"),
    },
  ],
  component: () => import("./AccountInfo.vue"),
  redirect: "/accountinfo/accountlevel",
};

export const BookMonthly = {
  name: "BookMonthly",
  path: "/monthly",
  meta: {
    Chinese: "包月",
    auth: true,
    Skeleton: "monthly",
  },
  component: () => import("./BookMonthly.vue"),
};

export const Helper = {
  name: "Help",
  path: "/help",
  meta: {
    Chinese: "帮助",
    Skeleton: "",
  },
  component: () => import("./Help.vue"),
};

export const TeenAgers = {
  name: "TeenAgers",
  path: "/teenagers",
  meta: {
    Chinese: "青少年模式",
    Skeleton: "",
  },
  component: () => import("./TeenAgers.vue"),
};

export const TeenAgersLock = {
  name: "TeenAgersLock",
  path: "/teenagerslock",
  meta: {
    Chinese: "青少年模式",
    Skeleton: "",
  },
  component: () => import("./TeenAgersLock.vue"),
};
export const Agreements = {
  name: "Agreements",
  path: "/agreements",
  meta: {
    Chinese: "充值协议",
    Skeleton: "",
  },
  component: () => import("./Agreement.vue"),
};

export const Login = {
  name: "Login",
  path: "/login",
  meta: {
    Chinese: "登录",
    Skeleton: "",
  },
  component: () => import("./Login.vue"),
};

/**
 * 重定向阅读器
 */
export const BookReaderV2 = {
  name: "readerV2",
  path: "/book/:bid/:chapter_id",
  redirect: "/reader/:bid/:chapter_id",
};

/**
 * 重定向user
 */
export const UserV2 = {
  name: "user2",
  path: "/user",
  redirect: "/personalcenter",
};

/**
 * 重定向最近阅读
 */
export const recentV2 = {
  name: "recentV2",
  path: "/user/bookcase",
  redirect: "/stock/recent",
};

/**
 * 重定向书籍详情
 */
export const detailV2 = {
  name: "detailV2",
  path: "/book/:bid",
  redirect: "/detail/:bid",
};

export const UnderTheShelf = {
  name: "UnderTheShelf",
  path: "/undershelf",
  meta: {
    Chinese: "书籍已下架",
    Skeleton: "",
  },
  component: () => import("./BookUnderTheShelf.vue"),
};

/**
 * 签到第一版本
 */
export const Sign = {
  name: "Sign",
  path: "/sign",
  meta: {
    Chinese: "签到",
    Skeleton: "",
  },
  component: () => import("./Sign.vue"),
};

/**
 * 签到第二版本
 */
export const Sign2 = {
  name: "Sign2",
  path: "/sign",
  meta: {
    Chinese: "签到",
    Skeleton: "",
  },
  component: () => import("./Sign2.vue"),
};

/**
 * 签到第三版本
 */
export const Sign3 = {
  name: "Sign3",
  path: "/sign",
  meta: {
    Chinese: "签到",
    Skeleton: "",
  },
  component: () => import("./Sign3.vue"),
};

/**
 * 答题签到
 */
export const Answer = {
  name: "Answer",
  path: "/answer",
  meta: {
    Chinese: "答题赢书币",
    Skeleton: "",
  },
  component: () => import("./Answer.vue"),
};

/**
 * 投诉
 */
export const Report = {
  name: "Report",
  path: "/report",
  meta: {
    Chinese: "投诉",
    Skeleton: "",
  },
  component: () => import("./Report.vue"),
};

/**
 * 经验记录
 */
export const ExperienceRecord = {
  name: "ExperienceRecord",
  path: "/experience",
  meta: {
    Chinese: "经验值历史记录",
    Skeleton: "",
  },
  component: () => import("./ExperienceRecord.vue"),
};

/**
 * 作者中心
 */
export const AuthorOperator = {
  name: "AuthorOperator",
  path: "/author-operator",
  meta: {
    Chinese: "作者中心",
    Skeleton: "",
  },
  component: () => import("./AuthorOperator.vue"),
};

/**
 * 书籍管理
 */
export const BookManager = {
  name: "BookManager",
  path: "/book-manager",
  meta: {
    Chinese: "书籍管理",
    Skeleton: "",
  },
  component: () => import("./BookManager.vue"),
};

/**
 * 草稿箱
 */
export const DraftBox = {
  name: "DraftBox",
  path: "/draft",
  meta: {
    Chinese: "存稿箱",
    Skeleton: "",
  },
  component: () => import("./DraftBox.vue"),
};

/**
 * 章节管理
 */
export const ChapterManager = {
  name: "ChapterManager",
  path: "/chapter-manager",
  meta: {
    Chinese: "章节管理",
    Skeleton: "",
  },
  component: () => import("./ChapterManager.vue"),
};

/**
 * 日期
 */
export const Calendar = {
  name: "Calendar",
  path: "/calendar",
  meta: {
    Chinese: "日期",
    Skeleton: "",
  },
  component: () => import("./Calendar.vue"),
};

/**
 * 读者风云榜
 */
export const ReaderBillboard = {
  name: "ReaderBillboard",
  path: "/reader-billboard",
  meta: {
    Chinese: "读者风云榜",
    Skeleton: "",
  },
  component: () => import("./ReaderBillboard.vue"),
};

/**
 * 送红包
 */
export const SendRedPackets = {
  name: "SendRedPackets",
  path: "/send-red-packets",
  meta: {
    Chinese: "发红包给TA",
    Skeleton: "",
  },
  component: () => import("./SendRedPackets.vue"),
};

/**
 * 用户红包
 */
export const UserRedPacket = {
  name: "UserRedPacket",
  path: "/user-red-packet",
  meta: {
    Chinese: "我的红包",
    Skeleton: "",
  },
  component: () => import("./UserRed.vue"),
};

/**
 * 作者领红包
 */
export const AuthorRedPacket = {
  name: "AuthorRedPacket",
  path: "/author-red-packet",
  meta: {
    Chinese: "我的红包",
    Skeleton: "",
  },
  component: () => import("./AuthorRedPacket.vue"),
};

/**
 * 作者领红包
 */
export const AuthorRedPacketRecord = {
  name: "AuthorRedPacketRecord",
  path: "/author-red-packet-record",
  meta: {
    Chinese: "作者红包领取记录",
    Skeleton: "",
  },
  component: () => import("./AuthorRedPacketRecord.vue"),
};

/**
 * 作者领红包
 */
export const WeekInteractRank = {
  name: "WeekInteractRank",
  path: "/week-interact-rank",
  meta: {
    Chinese: "本周互动榜单",
    Skeleton: "",
  },
  component: () => import("./WeekInteractRank.vue"),
};

/**
 * 红包详情
 */
export const RedDetail = {
  name: "RedDetail",
  path: "/red-detail",
  meta: { Chinese: "红包详情", Skeleton: "" },
  component: () => import("./RedDetail.vue"),
};

export const NotFound = {
  name: "notfound",
  path: "*",
  redirect: "/",
};
