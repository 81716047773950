if (process.env.NODE_ENV === "development") {
  window.ycsd = {
    is_app: 0,
    is_author: 0,
    distribution_channel_id: 5276,
    show_app_new_guide: true,
    send_order_id: null,
    uid: null,
    pay_url: "http://baidu.com",
    title: "不知名标题",
    login_redirect_silent: "",
    islogin: false,
    client_redirect: "",
    weekCard: true,
    isShowActivity: true,
    isActivate: true,
    isPayActivityShow: true,
    kf_time: "13:00 - 17:00",
    kf_phone: "110",
    big_pay_template_type: "common",
    book_detail: "api",
    is_main_site: 0,
    user_rank_show: 1,
    pay_config: {
      pay_config: [
        {
          id: 2,
          nick: "OFFICIAL",
          nickname: "微信官方支付",
          logo_url:
            "https://otzsy.oss-cn-hangzhou.aliyuncs.com/base/logo/weixin.jpg",
          redirect_url: "https://newpay.ycsd.cn/goToPay",
          status: "1",
          created_at: null,
          updated_at: null,
        },
        {
          id: 1,
          nick: "ALIPAY",
          nickname: "支付宝",
          logo_url:
            "https://otzsy.oss-cn-hangzhou.aliyuncs.com/base/logo/zhifubao.jpg",
          redirect_url: "https://newpay.ycsd.cn/aliPay",
          status: "1",
          created_at: null,
          updated_at: null,
        },
      ],
      pay_nicks: ["OFFICIAL", "ALIPAY"],
    },
    login_config: {
      login_config: [
        {
          id: 1,
          nick: "QQ",
          nickname: "QQ\u767b\u5f55",
          logo_url: "https://cdn.ycsd.cn/ycsd_web_3nd/images/icon-qq.png",
          redirect_url: "http://publicapi.ycsd.cn/api/auth/qq_auth",
          is_redirect: 0,
          status: "1",
          is_third_login: 1,
          created_at: null,
          updated_at: null,
        },
        {
          id: 2,
          nick: "WEIBO",
          nickname: "\u5fae\u535a\u767b\u5f55",
          logo_url: "https://cdn.ycsd.cn/ycsd_web_3nd/images/icon-sina.png",
          redirect_url: "http://publicapi.ycsd.cn/api/auth/weibo_auth",
          is_redirect: 0,
          status: "1",
          is_third_login: 1,
          created_at: null,
          updated_at: null,
        },
        {
          id: 3,
          nick: "WECHAT",
          nickname: "\u5fae\u4fe1\u767b\u5f55",
          logo_url: "https://cdn.ycsd.cn/ycsd_web_3nd/images/wx_22.png",
          redirect_url: "http://publicapi.ycsd.cn/api/auth/wechat_auth",
          is_redirect: 0,
          status: "1",
          is_third_login: 1,
          created_at: null,
          updated_at: null,
        },
      ],
      login_nicks: ["QQ", "WEIBO", "WECHAT"],
      login_redirect: "/personalcenter?base_domain=pre.fpread.com",
      base_domain: "pre.fpread.com",
    },
    week_card: {
      id: 1,
      name: "\u5468\u5361\u6d3b\u52a8",
      created_at: "2019-05-24 18:21:55",
      updated_at: "-0001-11-30 00:00:00",
      start_time: "2019-07-08 00:00:00",
      end_time: "2019-07-14 00:00:00",
      activity_page: "",
      product_id: "47",
      token: "",
      default_template_id: 0,
      customer_msg: "",
      distribution_channel_id: 0,
      is_activate: 0,
      is_show: true,
      limit: 2,
      sign: 0,
      img: "http://cdn-newyc.ycsd.cn/h5/activity-2020-07-08/img/alert.png",
    },
    activity_position: {
      recent: [
        {
          img: "https://cdn-newyc.ycsd.cn/h5/activity-2019-11-25/float.png",
          extra: { type: "", cardGainCoin: 0 },
        },
      ],
      global: [
        {
          img:
            "https://cdn-newyc.ycsd.cn/h5/activity-2020-11-24/imgs/alert.png",
          link:
            "https://m.ycsd.cn/activity/nationalDayView?fromtype=activity_global",
          start_time: "2019-09-30",
          end_time: "2019-10-07",
          type: "crm",
          extra: {
            activity: {},
            cardGainCoin: 0,
            subCoinData: {
              minConsumeCoin: 600,
              subCoin: 20,
              giveCoin: 20,
              needCoin: 20,
              coin: 20,
            },
          },
        },
      ],
      reader_banner: [
        {
          img:
            "https://cdn-newyc.ycsd.cn/ycsd_web_3nd/images/homebanners/纯情先生狂宠妻（1000390）改.jpg",
          link: "http://baidu.com",
        },
        {
          img: "https://cdn-newyc.ycsd.cn/h5/activity-2020-09-23/banner3.jpg",
          link: "http://baidu.com",
        },
      ],
      reader_tip: [],
      recent_banner: [
        {
          title: "\u5305\u6708",
          img:
            "https://cdn-newyc.ycsd.cn/ycsd_web_3nd/images/baoyue_20200318.jpg",
          link: "/monthly",
          type: "bag",
          position: "recent_banner",
          extra: {},
        },
        {
          title: "\u4e66\u8bc4\u6d3b\u52a8",
          img:
            "http://cdn-newyc.ycsd.cn/h5/activity-2020-03-17/comment_enter.jpg",
          link: "/activity/bookCommentIndexView?send_pos=recent_banner",
          type: "activity",
          position: "recent_banner",
          extra: {},
        },
      ],
      author_center: [
        {
          title: "\u4e66\u8bc4\u6d3b\u52a8\u4f5c\u8005\u8bc4\u5206",
          img: "https://cdn-newyc.ycsd.cn/h5/activity-2020-03-17/mark.png",
          link:
            "/activity/bookCommentMarkView/qvQ0OXLZE2ba3KnabERoDNzyJxMBrAVw",
          type: "activity",
          position: "author_center",
          extra: {},
        },
      ],
    },
    activity: {
      reader_banner: [
        {
          img:
            "https://cdn-newyc.ycsd.cn/ycsd_web_3nd/images/homebanners/纯情先生狂宠妻（1000390）改.jpg",
          link: "http://baidu.com",
        },
        {
          img: "https://cdn-newyc.ycsd.cn/h5/activity-2020-09-23/banner3.jpg",
          link: "http://baidu.com",
        },
      ],
      global: {
        img: "https://cdn-newyc.ycsd.cn/h5/activity-2019-09-10/global.jpg",
        link:
          "https://m.ycsd.cn/activity/nationalDayView?fromtype=activity_global",
        start_time: "2019-09-30",
        end_time: "2019-10-07",
        extra: { activity: {} },
      },
      recent: {
        img: "https://cdn-newyc.ycsd.cn/h5/activity-2019-11-25/float.png",
        extra: { type: "" },
      },
    },
    guide_personal_account: {
      title: "我是文案",
      link: "https://www.baidu.com?uid=1",
      banner: "https://cdn-novel.iycdm.com/crm/guide/reader-banner.jpg",
      float_entry: require("../assets/week-card-entry.png"),
    },
    down_app_float: {
      show: true,
      title: "",
      link: "https://baidu.com",
      banner: "",
      float_entry: require("../assets/app_download_entry.png"),
    },
    wechat_float: {
      show: true,
      content: "点击此处，收藏本书",
      ios_img: "https://cdn-newyc.ycsd.cn/h5/iphone_collect.png",
      android_img: "https://cdn-newyc.ycsd.cn/h5/android_collect.png",
    },
  };
  // {
  //   title: "包月",
  //   img: "",
  //   link: "/monthly",
  //   type: "bag",
  //   position: "global",
  //   extra: {
  //     bagAlert: {
  //       bid: "2VkOMpjBem8ZNgrx5eKY7d9r5LE03Dwz",
  //       name: "我想要的她都有",
  //       cover:
  //         "https://cdn-newyc.ycsd.cn/ycsd_cover/covermiddle/17/17064.jpg",
  //       intro:
  //         "&nbsp;&nbsp;&nbsp;&nbsp;从小我就嫉妒柳咏诗，我想要的她都有。<br />&nbsp;&nbsp;&nbsp;&nbsp;明明她有那么多追求者，还抢走我爱的人。<br />&nbsp;&nbsp;&nbsp;&nbsp;她不仁在先，就不要怪我不义了......",
  //       author: "蓝安安",
  //       desc: "2元/本 查看书包",
  //     },
  //   },
  // },
  // {
  //   img: "",
  //   link: "/mycomment",
  //   start_time: "2019-09-30",
  //   end_time: "2019-10-07",
  //   type: "red_packet",
  //   extra: {
  //     pack_type: "comment"
  //   }
  // }
}
