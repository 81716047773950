/* eslint-disable */
import * as type from "./mutation-type";

const str = window.localStorage;

const mutation = {
  [type.SET_ROUTER_PATH](state, path) {
    state.router = path;
  },
  [type.SET_LOGIN_STATUS](state, status) {
    state.isLogin = status;
    // * cookie跨域 暂时使用缓存
    str.setItem("isLogin", status);
  },
  [type.SET_BROWER_TYPE](state, type) {
    state.browerType = type;
    str.setItem("browerType", type);
  },
  [type.OPEN_DIALOG](state) {
    state.show = true;
  },
  [type.CLOSE_DIALOG](state) {
    state.show = false;
  },
  [type.DIALOG_ANSWER_CHOOSE](state) {
    console.log(state);
    state.dialog_answer_choose = true;
  },
  [type.RECORD_APP_STATUS](state) {
    state.isApp = !!window.ycsd.is_app;
  },
  [type.RECORD_APP_ROUTER_STACK](state, path) {
    state.routerStack.push(path);
  },
  [type.REMOVE_APP_LAST_ROUTER](state) {
    state.routerStack.pop();
  },
};

export default mutation;
