<template>
  <div class="x-com__item">
    <div class="x-com__header">
      <div class="x-com__header--user">
        <div class="head-img__box"
             @click.stop="toReaderCenter(comment.user.uid)">
          <img :src="comment.user.avatar || '../../assets/1.png'"
               class="head-img"
               alt="">
          <img v-if="comment.user.groupLevel"
               :src="getaccountlevel(comment.user.groupLevel)"
               class="user-account-lv"
               alt="">
        </div>
        <div class="x-com__header--userlv">
          <p>{{comment.user.nickname}}</p>
          <div class="lv-box">
            <img v-if="comment.user.vipLevel"
                 :src="getviplevel(comment.user.vipLevel)"
                 alt=""
                 class="user-vip">
            <div class="x-com__header--level"
                 v-if="comment.user.forumLevel">{{comment.user.forumLevel}}级评论员</div>
          </div>
        </div>
        <img v-if="comment.isEssence"
             class="is_essence"
             src="../../assets/评论/精华评论.png"
             alt="">
      </div>
      <!-- 内容标识插槽 -->
      <!-- <slot name="tag">
        <p class="x-com__red">收到红包标识</p>
      </slot> -->
      <div class="has-red-packet"
           v-if="comment.redPacket.id"
           @click.stop="getCommentRedPacket(comment.redPacket)">
        <img :src="comment.redPacket.isReceived ? red.isReceive : red.unReceive"
             alt="">
      </div>
    </div>
    <div class="x-com__red-packet-intro"
         v-if="comment.redPacket.id">
      <div class="text">
        <img src="../../assets/comment-red-target.png"
             alt="">
        该评论收到作者红包奖励
      </div>
      <router-link tag="span"
                   :to="{ name: Helper.name }">查看获取红包攻略&gt;</router-link>
    </div>
    <div class="x-com__content"
         @click="getCommentDetail(comment.bid, comment.id)">
      <div class="x-com__content--title"
           v-if="comment.title"
           v-html="formatContentWithEmoji(comment.title)"></div>
      <p class="x-com__content--text"
         v-html="formatContentWithEmoji(comment.content)"></p>
      <div class="x-com__content--created">
        <div class="x-com__content--time">{{comment.created_at}}</div>
        <div class="x-com__content--operator"
             v-if="!hideNums">
          <span class="x-com__operator--comment"
                @click.stop="toReply(comment.id)">
            <img src="../../assets/评论/评论.png"
                 alt="">{{comment.replyCount}}</span>
          <!-- <span class="x-com__operator--tap">点赞{{comment.tap_nums || 0}}</span> -->
        </div>
      </div>
      <!-- 内容底下的插槽 -->
      <slot></slot>
    </div>
    <slot name="more"></slot>
    <div class="x-com__reply"
         v-if="comment.replyList && comment.replyList.length && !hideLineReply">
      <div class="x-reply__item van-hairline--bottom"
           v-for="(r, i) in comment.replyList"
           :key="i">
        <img :src="r.user.avatar||defaultAvatar"
             alt="">
        <div class="x-reply__item--user">
          <p class="x-reply__item--time">{{r.user.nickname}}<span>{{r.postTime}}</span></p>
          <p class="x-reply__item--content"
             v-html="formatContentWithEmoji(r.content)"></p>
        </div>
      </div>
      <div class="x-reply__more"
           @click="getCommentDetail(comment.bid, comment.id)"
           v-if="comment.replyCount > 2">展开剩余{{comment.replyCount - 2}}条回复</div>
    </div>
    <div class="x-com__send-red-packet"
         v-if="isAuthor"
         @click.stop="toSendRedPacket(comment.id)">
      <img src="../../assets/author-send-red.png"
           alt="">
      <span>给TA发红包</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getviplevel, getaccountlevel } from "@/utils/getlevel.js";
import { formatContentWithEmoji } from "@/utils/emoji.js";
import { SendRedPackets, Helper } from "@/views";

import { RedPacketInComment } from "@/components/dialog";

import { getRedPacketInComment } from "@/api";

export default {
  name: "XComment",
  props: {
    comment: {
      type: [Object],
      default: {}
    },
    prevent: {
      type: Boolean,
      default: false
    },
    hideNums: {
      // * 是否行内显示点赞数评论数
      type: Boolean,
      default: false
    },
    hideLineReply: {
      // * 是否行内展示回复
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultAvatar: require("../../assets/1.png"),
      red: {
        unReceive: require("../../assets/comment-red-target.gif"),
        isReceive: require("../../assets/red-got.png")
      },
      Helper
    };
  },
  methods: {
    getCommentDetail(bid, comment_id) {
      // * 获取书评详情
      // this.$router.push(`/comment_detail/${comment_id}`)
      if (!this.prevent) {
        this.$router.push(
          `/comment_detail?bid=${bid}&comment_id=${comment_id}`
        );
      }
    },
    toReply(id) {
      this.$emit("reply", id);
    },
    toReaderCenter(uid) {
      this.$router.push(`/readers/${uid}`);
    },
    toSendRedPacket(id) {
      this.$router.push({
        name: SendRedPackets.name,
        query: {
          comment_id: id
        }
      });
    },
    async getCommentRedPacket(red) {
      try {
        let result = await getRedPacketInComment(red.id);
        RedPacketInComment({ info: result })
          .then(r => {
            console.log("close");
          })
          .catch(e => console.log(e));
      } catch (e) {
        this.$toast(e.msg);
      }
    },
    getviplevel,
    getaccountlevel,
    formatContentWithEmoji
  }
};
</script>

