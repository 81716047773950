/*
 * @Author: 晓晓晓晓晓丶vv
 * @Date: 2020-04-22 10:31:56
 * @LastEditors: 晓晓晓晓晓丶vv
 * @LastEditTime: 2020-05-07 16:16:54
 * @Description: app交互
 * @FilePath: /src/utils/app_register.js
 */

import { initAppClient, app } from "./app";

export const appLogin = () => {
  initAppClient({ ios_method: "loginApp", android_method: "startLogin" });
};

export const afterRouteChange = (title) => {
  initAppClient({
    ios_method: "afterRouteChange",
    android_method: "afterRouteChange",
    params: { title },
  });
};

export const jumpBookInfoByApp = (bid) => {
  initAppClient({
    ios_method: "jumpBookInfo",
    android_method: "jumpBookInfo",
    params: {
      bid,
    },
  });
};

export const appRecharge = (params) => {
  initAppClient({ android_method: "charge", params });
};

export const switchRecharge = () => {
  initAppClient({
    ios_method: "switchRecharge",
    android_method: "startCharge",
  });
};

export const addShelfInApp = (bid) => {
  initAppClient({
    ios_method: "addBookToShelfBriderHandle",
    android_method: "addToBookShelf",
    params: {
      bid,
    },
  });
};

export const startToRead = (bid, cid, bookTitle, cover) => {
  initAppClient({
    ios_method: "readBookBridgeHandler",
    android_method: "JumpToRead",
    params: {
      bid: bid + "",
      cid: cid + "",
      bookTitle,
      cover,
      useCid: true,
    },
  });
};

export const appNotifyWebBack = () => {
  return new Promise((resolve) => {
    app.$on("back", () => {
      resolve();
    });
  });
};

export const isOnePageBack = () => {
  initAppClient({ android_method: "closePage" });
};
