<template>
  <div class="x-emoji">
    <template v-for="(emoji, i) in emojis">
      <img :src="emoji.url"
           :alt="emoji.name"
           :data-key="emoji.key"
           :key="i"
           @click="select(emoji.key)">
    </template>
  </div>
</template>

<script>
import { formatEmojis } from "@/utils/emoji.js";

export default {
  name: "XEmoji",
  data() {
    return {
      emojis: []
    };
  },
  methods: {
    select(EmojiKey) {
      this.$emit("select", `[em=${EmojiKey}]`);
    }
  },
  created() {
    this.emojis = formatEmojis();
  }
};
</script>
