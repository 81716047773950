<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask
                class="red-packet-dialog__wrap">
    <div class="red-packet-content">
      <img class="red-bg"
           src="./imgs/global-red-packet.png"
           alt="">
      <div class="red-packet-text">
        <p>您的评论</p>
        <span>获得了作者<i>红包奖励</i></span>
      </div>
      <div class="to-get"
           @click.stop="close">前去查看&gt;&gt;</div>
      <img src="./imgs/close.png"
           alt=""
           class="close"
           @click.stop="closeDialog">
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  methods: {
    closeDialog() {
      this.reject();
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.red-packet-dialog__wrap {
  .red-packet-content {
    font-size: 0;
    position: relative;

    img {
      width: 100%;

      &.red-bg {
        width: 1.36rem;
        margin-top: 0.47rem;
      }
    }

    .red-packet-text {
      font-size: 0.3rem;
      color: #333;

      p {
        font-size: 0.36rem;
      }

      i {
        color: #ef5952;
      }
    }

    .to-get {
      width: 3.3rem;
      margin: 0.53rem auto 0.28rem;
      height: 0.76rem;
      line-height: 0.76rem;
      border-radius: 0.38rem;
      background: #ef5952;
      font-size: 0.28rem;
      color: #fff;
      text-align: center;
    }

    .close {
      position: absolute;
      width: 0.3rem;
      height: 0.3rem;
      top: 0.2rem;
      right: 0.2rem;
    }
  }
}
</style>