/**
 * 接口数据格式化
 */
/* eslint-disable */
import actions from "./action";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ZhCn from "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
dayjs.extend(relativeTime);

const common = actions.common;

/**
 * 书籍字段格式化
 * @param {object} book
 */
export const BookFormat = (book) => {
  return {
    bid: book.bid,
    book_id: book.bid,
    cover: book.cover,
    name: book.bookTitle,
    chapter_id: book.chapterId,
    chapter: book.chapterTitle,
    last_chapter_id: book.lastCid,
    last_chapter: book.lastTitle,
    is_on_shelf: book.isAddBookCase,
  };
};

/**
 * 书库书籍字段格式化
 * @param {object} book
 */
export const LibraryFormat = (book) => {
  return {
    bid: book.bid || book.id,
    book_id: book.bid || book.id,
    name: book.name,
    cover: book.cover,
    update: book.lastUpdate,
    last_chapter: book.lastChapter,
    author: book.author,
    tags: book.labels,
    words_count: book.words,
    chapters: book.chapterList || [],
    tickets: book.bookData && {
      month: book.bookData.tickets || 0,
      recommend: book.bookData.recomm || 0,
      hits: book.bookData.hits,
    },
    is_vip: book.isVip || null,
    intro: book.intro && (book.info.Introduction || ""),
    state: book.isEnd,
  };
};

/**
 * 分类字段格式化
 * @param {array} cate 分类列表
 */
export const CategoryFormat = (cate) => {
  return cate.map((c) => {
    return {
      label: c.category_name,
      key: c.id,
    };
  });
};

/**
 * 参数格式化
 * @param {object} params 原有参数
 * @param {object} others 额外参数
 */
export const paramsFormat = (params, others) => {
  let data = {};
  params.bid && (data.bid = params.bid);
  params.action
    ? (data.action = params.action)
    : (data.action = common.getList);
  if (data.action === common.getList) data.page = params.page || 1;
  if (others) {
    if (typeof others === "object") data = Object.assign(data, others);
    else throw new Error("others must be a object");
  }
  return data;
};

/**
 * 格式化我的评论数据
 * @param {object} data 我的评论数据
 */
export const MyCommentFormat = (data) => {
  return data[0];
};

/**
 * 格式化红包广场的数据
 * @param {*} data 原始数据
 */
export const formatRedData = (data) => {
  var now = dayjs();
  return {
    bid: data.book.bid,
    cover: data.book.bookCover,
    name: data.book.bookName,
    comment: data.comment,
    createdAt: now.to(data.createdAt),
    isReceived: data.isReceived,
    packId: data.packId,
    packType: data.packType,
    state: data.state,
    avatar: data.user.avatar,
    nickname: data.user.nickname,
    uid: data.user.uid,
  };
};
