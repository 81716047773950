// 骨架屏组件
import "./skeletonComponents";

// 骨架屏
import Vue from "vue";
import XSkeletonHome from "./home.vue";
import XSkeletonLibrary from "./library.vue";
import XSkeletonDetail from "./detail.vue";
import XSkeletonComment from "./comment.vue";
import XSkeletonCommentDetail from "./comment-detail.vue";
import XSkeletonCatalog from "./catalog.vue";
import XSkeletonReader from "./reader.vue";
import XSkeletonRank from "./rank.vue";
import XSkeletonGift from "./gift.vue";
import XSkeletonRecent from "./recent.vue";
import XSkeletonPay from "./pay.vue";
import XSkeletonFans from "./fans.vue";
import XSkeletonMonthly from "./monthly.vue";

Vue.component(XSkeletonHome.name, XSkeletonHome);
Vue.component(XSkeletonLibrary.name, XSkeletonLibrary);
Vue.component(XSkeletonDetail.name, XSkeletonDetail);
Vue.component(XSkeletonComment.name, XSkeletonComment);
Vue.component(XSkeletonCommentDetail.name, XSkeletonCommentDetail);
Vue.component(XSkeletonCatalog.name, XSkeletonCatalog);
Vue.component(XSkeletonReader.name, XSkeletonReader);
Vue.component(XSkeletonRank.name, XSkeletonRank);
Vue.component(XSkeletonGift.name, XSkeletonGift);
Vue.component(XSkeletonRecent.name, XSkeletonRecent);
Vue.component(XSkeletonPay.name, XSkeletonPay);
Vue.component(XSkeletonFans.name, XSkeletonFans);
Vue.component(XSkeletonMonthly.name, XSkeletonMonthly);
