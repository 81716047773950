<!--
 * @Author: ZhengXiaowei
 * @Date: 2019-11-13 09:40:36
 * @LastEditors: ZhengXiaowei
 * @LastEditTime: 2019-11-15 18:37:52
 * @Description: 发布确认弹窗
 -->
<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask>
    <div class="publish-confirm-wrap">
      <div class="confirm-item">
        <span class="confirm-item__label">发布时间</span>
        <div class="confirm-item__content">
          <p class="publish-timerpicker"
             @click="showPickerAction">
            {{publishTime}}
            <span class="reset"
                  v-if="publishTime !== '点击选择日期...'"
                  @click.stop="publishTime = '点击选择日期...'">X</span>
          </p>
        </div>
      </div>
      <p class="notice">注：不选择时间，则默认为立即发布！</p>
      <!-- <div class="confirm-item">
        <span class="confirm-item__label">章节类型</span>
        <div class="confirm-item__content">
          <template v-for="(type, i) in publishType">
            <p :class="['publish-type-item', {'select': type.value === current}]"
               :key="i"
               @click="changeChapterVipMode(type.value)">{{type.name}}</p>
          </template>
        </div>
      </div> -->
      <p class="confirm-btn"
         @click="publish">确定</p>
    </div>
    <!-- TODO 抽离成单独组件调用问题居多 -->
    <!-- 时间选择器 -->
    <van-popup v-model="showPicker"
               position="bottom"
               :overlay="false"
               get-container="body">
      <van-datetime-picker v-model="currentDate"
                           :min-date="minDate"
                           type="datetime"
                           :formatter="timerFormat"
                           @confirm="updateTimer"
                           @cancel="showPicker = false" />
    </van-popup>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

const TIME_PLACEHOLDER = "点击选择日期...";

export default {
  components: {
    "x-err-dialog": errDialog
  },
  data: () => ({
    current: 1,
    isEdit: false,
    showPicker: false,
    minDate: new Date(),
    currentDate: new Date(),
    publishTime: TIME_PLACEHOLDER,
    publishType: [{ name: "VIP章节", value: 1 }, { name: "免费章节", value: 0 }]
  }),
  methods: {
    updateTimer(time) {
      let dateArr = time.toLocaleString().split(" ");
      let date = dateArr[0];
      let timeArr = dateArr[1].split(":");
      timeArr.splice(0, 1);
      let times = time.getHours() + ":" + timeArr.join(":");
      this.publishTime = date + " " + times;
      this.showPicker = false;
    },
    timerFormat(type, value) {
      if (type === "year") return value + "年";
      else if (type === "month") return value + "月";
      else if (type === "day") return value + "日";
      else if (type === "hour") return value + "时";
      else if (type === "minute") return value + "分";
      return value;
    },
    showPickerAction() {
      // 免费章节 不可弹
      if (this.isDraft !== undefined && !this.isDraft) {
        this.$toast("已发布章节不能选择发布时间！");
        return;
      }
      this.showPicker = !this.showPicker;
    },
    publish() {
      this.showPicker = false;
      this.resolve({
        autoTime: this.publishTime === TIME_PLACEHOLDER ? "" : this.publishTime,
        isDraft: this.isDraft
      });
      this.visible = false;
    }
  },
  created() {
    if (this.isAuto) {
      this.publishTime = this.autoTime.replace(/\-/g, "/");
      this.currentDate = new Date(this.publishTime);
      // 可选时间默认+1分钟
      let min_date_add_one_minute = this.currentDate.getTime() + 1000 * 60;
      this.minDate = new Date(min_date_add_one_minute);
    }
  }
};
</script>

<style lang="scss" scoped>
.publish-confirm-wrap {
  padding: 0.4rem 0.3rem;

  .confirm-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.4rem;
    }

    .confirm-item__label {
      width: 1.2rem;
      font-size: 0.26rem;
      color: #333;
      margin-right: 0.1rem;
    }

    .confirm-item__content {
      flex: 1;
      display: flex;

      p {
        flex: 1;
      }

      .publish-timerpicker {
        background: #f7f7f7;
        height: 0.7rem;
        line-height: 0.7rem;
        font-size: 0.24rem;
        color: #999;
        text-align: left;
        padding: 0 0.24rem;
        position: relative;

        .reset {
          position: absolute;
          right: 0.24rem;
          top: 50%;
          transform: translate(0, -50%);
          color: #333;
          width: 0.4rem;
          text-align: center;
        }
      }

      .publish-type-item {
        height: 0.7rem;
        line-height: 0.7rem;
        border-radius: 0.04rem;
        background: #f7f7f7;
        color: #ccc;
        font-size: 0.24rem;
        border: 1px solid #f7f7f7;
        position: relative;

        &:last-child {
          margin-left: 0.16rem;
        }

        &.select {
          background: #fff;
          color: #ee5750;
          border-color: #ee5750;

          &::after {
            content: "";
            position: absolute;
            right: -1px;
            top: -1px;
            background: url(~@/components/dialog/slots/imgs/type-check.png)
              no-repeat center / contain;
            width: 0.32rem;
            height: 0.32rem;
          }
        }
      }
    }
  }

  .notice {
    color: #ee5750;
    font-weight: bold;
    font-size: 0.28rem;
    text-align: center;
    margin: 0.2rem 0;
  }

  .confirm-btn {
    background: #ee5750;
    color: #fff;
    font-size: 0.28rem;
    text-align: center;
    border-radius: 0.04rem;
    height: 0.8rem;
    line-height: 0.8rem;
    width: 90%;
    margin: 0 auto;
  }
}
</style>