<template>
  <x-err-dialog :visible="visible"
                close-on-click-mask>
    <div class="chapter-pay-dialog">
      <p class="dialog-title van-hairline--bottom">书殿客服 <span class="close"
              @click.stop="visible=false"></span></p>
      <div class="custom-content">
        <p class="custom-item"><img src="../../../assets/电话.png"
               alt="">客服电话：<i>0571-56680189</i></p>
        <p class="custom-item"><img src="../../../assets/QQ.png"
               alt="">客服QQ：<i>3195564083</i></p>
        <div class="custom-notice">
          <p>注：如果您在使用过程中遇到任何问题，请随时联系我们哦，专业客服为您解答，祝您阅读愉快！</p>
        </div>
      </div>
    </div>
  </x-err-dialog>
</template>

<script>
import errDialog from "../err-dialog";

export default {
  components: {
    "x-err-dialog": errDialog
  }
};
</script>
