<template>
  <div class="x-menu">
    <router-link tag="div"
                 v-for="(menu, i) in menuList"
                 :to="menu.route"
                 class="x-menu__item"
                 :key="i">
      <img :src="menu.icon"
           :alt="menu.name">
      <span>{{menu.name}}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "XMenu",
  data() {
    return {
      menuList: [
        {
          name: "最近阅读",
          icon: require("../../assets/recent.png"),
          route: "/stock"
        },
        {
          name: "书库",
          icon: require("../../assets/library.png"),
          route: "/library"
        },
        {
          name: "排行榜",
          icon: require("../../assets/rank.png"),
          route: "/rank"
        },
        {
          name: "充值",
          icon: require("../../assets/pay.png"),
          route: "/recharge"
        },
        {
          name: "个人中心",
          icon: require("../../assets/user.png"),
          route: "/personalcenter"
        }
      ]
    };
  }
};
</script>
